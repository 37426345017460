/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import * as Ast from './ast';
import { AnnotatedSExp } from './read';
import { Span } from "./span";
import { ParseError, fail, inset, failIfPresent } from './parseerror';

type X = AnnotatedSExp;

function form(x: X, s: string): AnnotatedSExp[] {
    if (Array.isArray(x.value) && x.value[0]?.value === Symbol.for(s)) {
        return x.value.slice(1);
    }
    fail(x, `try a "${s}" form`);
}

function arr(x: X, what: string): AnnotatedSExp[] {
    if (Array.isArray(x.value)) return x.value;
    fail(x, `add ${what}`);
}

type AltCut = () => void;

function alt<R>(x: X, fs: ((x: X, cut: AltCut) => R)[]): R {
    let errors: ParseError[] = [];
    let isCut = false;
    for (const f of fs) {
        if (isCut) break;
        try {
            return f(x, () => isCut = true);
        } catch (e) {
            if (e instanceof ParseError) {
                errors.push(e);
                continue;
            }
            throw e;
        }
    }
    throw ParseError.merge(errors);
}

export function parseConst(x: X): Ast.Const {
    switch (typeof x?.value) {
        case 'string':
        case 'number':
        case 'boolean':
            return {type: 'const', span: x.span, literal: x.value};
        default:
            fail(x, 'supply a constant');
    }
}

export function parseVar(x: X, what = 'a variable'): Ast.Var {
    switch (typeof x?.value) {
        case 'symbol':
            return {type: 'var', span: x.span, name: x.value.description!};
        default:
        fail(x, `supply ${what}`);
    }
}

export function parseTerm(x: X): Ast.Term {
    return alt<Ast.Term>(x, [parseDef, parseCheck, parseExpr]);
}

export function parseDef(x: X, outerCut: AltCut): Ast.Def {
    function parseDefvar(x: X): Ast.Def {
        const [v, e, z] = form(x, 'defvar');
        outerCut();
        const expr = parseExpr(e ?? fail(inset(x), 'supply an initializer'));
        const name = parseVar(v ?? fail(inset(x), 'supply a variable name'));
        failIfPresent(z);
        return {type: 'defvar', span: x.span, name, expr};
    }

    function parseDeffun(x: X): Ast.Def {
        const [head, ... bodyX] = form(x, 'deffun');
        outerCut();
        const [f, ... vs] = arr(head ?? fail(inset(x), 'supply a function header'), 'parentheses around the function header');
        const name = parseVar(f ?? fail(inset(head), 'supply a function name'), 'the name to use for the function');
        const formals = vs.map(v => parseVar(v, 'an argument name'));
        const body = parseBody(x.span, bodyX);
        return {type: 'deffun', span: x.span, name, formals, body};
    }

    function parseDefrec(x: X): Ast.Def {
        const [n, ... fs] = form(x, 'defrec');
        outerCut();
        const name = parseVar(n ?? fail(inset(x), 'supply a record type name'));
        const fields = fs.map(f => parseVar(f, 'a record field name'));
        return {type: 'defrec', span: x.span, name, fields};
    }

    return alt(x, [parseDefvar, parseDeffun, parseDefrec]);
}

function parseCheck(x: X, outerCut: AltCut): Ast.Check {
    const bodyX = form(x, 'check');
    outerCut;
    const body = parseBody({ start: bodyX[0]?.span.start ?? x.span.start, end: x.span.end }, bodyX);
    return {type: 'check', span: x.span, body};
}

export function parseExpr(x: X): Ast.Expr {
    return alt<Ast.Expr>(x, [
        parseConst,
        x => parseVar(x),
        parseSet,
        parseIf,
        parseCond,
        parseLambda,
        parseLet,
        parseBegin,
        parseMatchrec,
        parseCall]);
}

function parseSet(x: X): Ast.Expr {
    const [v, e, z] = form(x, 'set!');
    const varName = parseVar(v ?? fail(inset(x), 'supply a variable name'));
    const expr = parseExpr(e ?? fail(inset(x), 'supply an expression'));
    failIfPresent(z);
    return {type: 'set', span: x.span, varName, expr};
}

function parseIf(x: X): Ast.Expr {
    const [test, t, f, z] = form(x, 'if');
    failIfPresent(z);
    return {
        type: 'cond',
        span: x.span,
        clauses: [{
            span: test?.span,
            test: parseExpr(test ?? fail(inset(x), 'supply a test in if')),
            body: {type: 'body', span: t?.span, terms: [], expr: parseExpr(t ?? fail(inset(x), 'supply a true clause'))},
        }],
        elseClause: {type: 'body', span: f?.span, terms: [], expr: parseExpr(f ?? fail(inset(x), 'supply a false clause'))},
    };
}

function parseCond(x: X): Ast.Expr {
    const unparsedClauses = form(x, 'cond');
    let elseClause: Ast.Body | undefined = void 0;
    if (unparsedClauses.length > 0) {
        const lastClause = unparsedClauses[unparsedClauses.length - 1];
        elseClause = alt(lastClause, [
            c => parseBody(c.span, form(c, 'else')),
            _c => void 0]);
        if (elseClause !== void 0) unparsedClauses.pop();
    }
    const clauses = unparsedClauses.map(parseCondClause);
    return {type: 'cond', span: x.span, clauses, elseClause};
}

function parseCondClause(x: X): Ast.CondClause {
    const [test, ... body] = arr(x, 'cond clause');
    return {
        span: x.span,
        test: parseExpr(test ?? fail(inset(x), 'supply a cond test')),
        body: parseBody(x.span, body),
    };
}

function parseLambda(x: X): Ast.Expr {
    const [formalsX, ... body] = form(x, 'lambda');
    const formals = arr(formalsX ?? fail(inset(x), 'supply a lambda argument list and body'), 'a lambda argument list').map(
        v => parseVar(v, 'an argument name'));
    return {type: 'lambda', span: x.span, formals, body: parseBody(x.span, body)};
}

function parseLet(x: X): Ast.Expr {
    const [bindingsX, ... body] = form(x, 'let');
    const bindings = arr(bindingsX ?? fail(inset(x), 'supply a let binding list and body'), 'a let binding list').map(
        parseBinding);
    return {type: 'let', span: x.span, bindings, body: parseBody(x.span, body)};
}

function parseBinding(x: X): Ast.LetBinding {
    const [v, e, z] = arr(x, 'a binding name and initializer');
    failIfPresent(z);
    return {
        span: x.span,
        varName: parseVar(v ?? fail(inset(x), 'supply a binding name'), 'a binding name'),
        expr: parseExpr(e ?? fail(inset(x), 'supply a binding initializer')),
    };
}

function parseBegin(x: X): Ast.Expr {
    const exprs = form(x, 'begin');
    if (exprs.length < 1) fail(inset(x), 'add an expression in begin');
    return {
        type: 'begin', 
        span: x.span,
        prelude: exprs.slice(0, -1).map(parseExpr),
        expr: parseExpr(exprs[exprs.length - 1]),
    };
}

function parseMatchrec(x: X): Ast.Expr {
    const [exprX, ... unparsedClauses] = form(x, 'matchrec');
    const expr = parseExpr(exprX ?? fail(inset(x), 'supply an expression to match'));
    let elseClause: Ast.Body | undefined = void 0;
    if (unparsedClauses.length > 0) {
        const lastClause = unparsedClauses[unparsedClauses.length - 1];
        elseClause = alt(lastClause, [
            c => parseBody(c.span, form(c, 'else')),
            _c => void 0]);
        if (elseClause !== void 0) unparsedClauses.pop();
    }
    const clauses = unparsedClauses.map(parseMatchrecClause);
    return {type: 'matchrec', span: x.span, expr, clauses, elseClause};
}

function parseMatchrecClause(x: X): Ast.MatchrecClause {
    const [pat, ... body] = arr(x, 'matchrec clause');
    const [typeName, ... fieldNames] =
        arr(pat ?? fail(inset(x), 'supply a pattern for this matchrec clause'), 'matchrec pattern');
    return {
        span: x.span,
        typeName: parseVar(typeName ?? fail(inset(pat), 'supply a record type name for this matchrec pattern'),
            'a matchrec pattern record type name'),
        fieldNames: fieldNames.map(n => parseVar(n, 'a matchrec pattern record field name')),
        body: parseBody(x.span, body),
    };
}

const RESERVED = ['begin', 'set!', 'if', 'cond', 'lambda', 'let'];

function parseCall(x: X): Ast.Expr {
    const [operator0, ... operands] = arr(x, 'call');
    const operator = operator0 ?? fail(inset(x), 'supply an operator');
    if (typeof operator.value === 'symbol' && RESERVED.indexOf(operator.value.description!) !== -1) {
        throw new ParseError([]); // no suggestions, other branches will report the problem
    }
    return {
        type: 'call',
        span: x.span, 
        operator: parseExpr(operator),
        actuals: operands.map(parseExpr),
    };
}

export function parseBody(span: Span, x: AnnotatedSExp[]): Ast.Body {
    if (x.length < 1) fail(inset({span}), 'add an expression to the body');
    const terms = x.slice(0, -1).map(parseTerm);
    const expr = parseExpr(x[x.length - 1]);
    return {type: 'body', span, terms, expr};
}

export function parseProgram(x: X): Ast.Program {
    return {type: 'program', span: x.span, terms: arr(x, 'program').map(parseTerm) };
}
