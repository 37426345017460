/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { Span, spanLt } from "./span";

export type Suggestion = { span: Span; suggestion: string; };
export class ParseError {
    readonly type = 'error';
    readonly suggestions: Suggestion[];
    constructor(suggestions: Suggestion[]) {
        if (suggestions.length === 0) {
            this.suggestions = [];
        } else {
            this.suggestions = [suggestions[0]];
            for (let i = 1; i < suggestions.length; i++) {
                if (spanLt(this.suggestions[0].span, suggestions[i].span)) {
                    this.suggestions = [suggestions[i]];
                } else if (spanLt(suggestions[i].span, this.suggestions[0].span)) {
                    // skip
                } else {
                    this.suggestions.push(suggestions[i]);
                }
            }
        }
    }
    static signal(start: number, end: number, suggestion: string): never {
        throw new ParseError([{ span: { start, end }, suggestion }]);
    }
    static merge(errs: ParseError[]): ParseError {
        const allSuggestions: Suggestion[] = [];
        errs.forEach(e => allSuggestions.push(...e.suggestions));
        return new ParseError(allSuggestions);
    }
}
export function inset(x: { span: Span; }, amount = 1): { span: Span; } {
    return { span: { start: x.span.start + amount, end: x.span.end - amount } };
}
export function fail(x: { span: Span; }, suggestion: string): never {
    throw new ParseError([{ span: x.span, suggestion }]);
}
export function failIfPresent<X extends { span: Span; }>(x: X | undefined): void {
    if (x === void 0) return;
    fail(x, 'remove this unexpected item');
}

