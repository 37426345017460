/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

export * as Ast from './ast';
export * as Contexts from './contexts';
export * as Interp from './interp';
export * as Parse from './parse';
export * as ParseError from './parseerror';
export * as Read from './read';
export * as Span from './span';
export * as TopEnv from './topenv';
export * as Values from './values';
export * as VariableAnalysis from './variableanalysis';
export * as Vm from './vm';
import './sets';

export { exhaustive } from './never';

export const examples = {
    fibonacci: `
(deffun (fib n)
  (cond
   [(<= n 1) 1]
   [else
    (+ (fib (- n 1))
       (fib (- n 2)))]))

(fib 0)
(fib 1)
(fib 2)
(fib 3)
`,
    scope: `
(defvar x 1)
(deffun (addx y)
  (defvar x 2)
  (+ x y))
(+ (addx 0) x)
`,
    counter1: `
(deffun (make-counter)
  (defvar n 0)
  (deffun (inc)
    (set! n (+ n 1))
    n)
  inc)
(defvar f (make-counter))
(f)
(f)
`,
    counter2: `
(defvar f
  (let ([n 0])
    (lambda ()
      (set! n (+ n 1))
      n)))
(f)
(f)
`,
    aliasing: `
(defvar v1 (vec 1 7 3))
(defvar v2 v1)
(vec-set! v1 0 42)
(vec-ref v2 0)
`,
    object: `
(defvar mk-o-static
  (let ([counter 0])
    (lambda (amount)
      (set! counter (+ 1 counter))
      (lambda (m)
        (cond
         [(eq? m "inc")
          (lambda (n) (set! amount (+ amount n)))]
         [(eq? m "dec")
          (lambda (n) (set! amount (- amount n)))]
         [(eq? m "get")
          (lambda () amount)]
         [(eq? m "count")
          counter]
         [else
          (error "no such member")])))))

(defvar o1 (mk-o-static 1000))
(defvar o2 (mk-o-static 0))
(o1 "count")
(o2 "count")
`,
    checks: `
(deffun (x v) (/ 10 v))

(check (= (x 5) 2))
(check (= (x 2) 2))
(check (= (x 0) 2))
`,
    map: `
(defrec cons head tail)
(defrec nil)

(deffun (map f xs)
  (matchrec xs
    [(nil) (nil)]
    [(cons head tail) (cons (f head) (map f tail))]))

(defvar xs (cons 1 (cons 2 (cons 3 (nil)))))
(map (lambda (x) (+ x 1)) xs)
`,
};

// import { InterpreterError, interpretProgram } from "./interp";
// import { makeTop } from "./topenv";
// import { parseProgram } from "./parse";
// import { read } from "./read";
// import { ParseError } from "./parseerror";
// import { excerptSpan } from "./span";
// const source = examples.object;
// try {
//     const xs = read(source, true);
//     // console.log(xs);
//     const p = parseProgram(xs);
//     // console.log(p);
//     interpretProgram(makeTop(), p);
// } catch (e) {
//     if (e instanceof ParseError) {
//         console.error('Parsing failed. Consider trying one of the following:');
//         e.suggestions.forEach(s => {
//             console.error(excerptSpan(source, s.span, s.suggestion));
//         });
//     } else if (e instanceof InterpreterError) {
//         console.error('Interpretation failed.');
//         console.error(excerptSpan(source, e.span, e.message));
//     } else {
//         throw e;
//     }
// }
