<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<svelte:options customElement="app-element" />

<script lang="ts">
  import "./carbon-components-svelte--all.css";

  import './bufferPolyfill.js';

  import ProjectPicker from './ProjectPicker.svelte';
  import FS from '@isomorphic-git/lightning-fs';
  import FileTree from "./FileTree.svelte";
  import { ContentSwitcher, OverflowMenuItem, Switch, Tabs } from "carbon-components-svelte";
  import FileTabLabel from "./FileTabLabel.svelte";
  import FileTabContent from "./FileTabContent.svelte";
  import { DEFAULT_SMOL_SETTINGS, smolSettings, type SmolSettings } from "./smolSettings.js";
  import Splitter from "./Splitter.svelte";
  import SettingsModal from "./SettingsModal.svelte";
  import { pathDecompose } from "./fsUtils.js";
  import { createExampleProject } from "./examples.js";
  import { onMount } from "svelte";
  import OverflowMenuSeparator from "./OverflowMenuSeparator.svelte";
  import ProjectSettingsModal from "./ProjectSettingsModal.svelte";
  import SwitchableContent from "./SwitchableContent.svelte";
  import GitPanel from "./GitPanel.svelte";

  type ProjectState = {
    selectedTab: number,
    openPaths: string[],
  };

  type PersistedState = {
    selectedProject: string | null,
    smolSettings: Partial<SmolSettings>,
    projectStates: Record<string, ProjectState>,
  };

  const loadedState: Partial<PersistedState> = JSON.parse(localStorage.getItem('smolts_ide_state') ?? '{}');

  let fs = new FS('smolts');

  let projectPath: string | null = null;
  $: selectedPath = projectPath || '/';

  let settingsOpen = false;
  let projectSettingsOpen = false;

  let selectedProject: string | null = loadedState.selectedProject ?? null;
  $: $smolSettings = Object.assign(DEFAULT_SMOL_SETTINGS, loadedState.smolSettings);
  let projectStates: Record<string, ProjectState> = loadedState.projectStates ?? {};

  let selectedSidePanel: number = 0;

  $: selectedProjectState = selectedProject === null ? null : (() => {
    if (!(selectedProject in projectStates)) {
      projectStates[selectedProject] = {
        selectedTab: -1,
        openPaths: [],
      };
      projectStates = projectStates; // reactive
    }
    return projectStates[selectedProject];
  })();

  let projectPicker: ProjectPicker;
  let fileTreeRoot: FileTree | undefined;
  onMount(async () => {
    await createExampleProject(fs);
    projectPicker.rescanProjects();
    fileTreeRoot?.rescan();
  });

  $: localStorage.setItem('smolts_ide_state', JSON.stringify({
    selectedProject,
    smolSettings: $smolSettings,
    projectStates,
  } satisfies PersistedState));

  function handleOpenFile(e: CustomEvent<string>) {
    if (selectedProjectState === null) return;
    const existingIndex = selectedProjectState.openPaths.indexOf(e.detail);
    if (existingIndex !== -1) {
      selectedProjectState.selectedTab = existingIndex;
    } else {
      selectedProjectState.openPaths.push(e.detail);
      const st = selectedProjectState;
      st.selectedTab = st.openPaths.length - 1;
      setTimeout(() => selectedProjectState = st, 100);
      projectStates = projectStates; // reactive
    }
    selectedProjectState = selectedProjectState; // reactive
  }

  function handleDeleted(e: CustomEvent<string>) {
    if (e.detail === projectPath) {
      setTimeout(() => projectPicker.rescanProjects(), 0);
    }
  }

  function handleRenamed(e: CustomEvent<{old: string, new: string}>) {
    if (e.detail.old === projectPath) {
      const d = pathDecompose(e.detail.new);
      setTimeout(async () => {
        await projectPicker.rescanProjects();
        selectedProject = d.filename;
      }, 0);
    }
  }

  function closeTab(e: CustomEvent<string>) {
    if (selectedProjectState === null) return;
    const existingIndex = selectedProjectState.openPaths.indexOf(e.detail);
    if (existingIndex !== -1) {
      selectedProjectState.openPaths.splice(existingIndex, 1);
      selectedProjectState.selectedTab = Math.min(
        selectedProjectState.selectedTab,
        selectedProjectState.openPaths.length - 1);
      selectedProjectState = selectedProjectState; // reactive
      projectStates = projectStates; // reactive
    }
  }
</script>

<style lang="scss">
  @import "smol.css";
  main {
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
  }
</style>

<main>
  <Splitter split="25%">
    <div slot="a" class="fill-space" style:display="flex" style:flex-direction="column">
      <ProjectPicker
        style="flex: 0 0 auto;"
        bind:this={projectPicker}
        bind:selectedProject
        bind:settingsOpen
        bind:fs
        bind:projectPath />
      {#if projectPath}
      <ContentSwitcher
        size="sm"
        style="flex: 0 0 auto; margin: 4px 0;"
        bind:selectedIndex={selectedSidePanel}
      >
        <Switch text="Files"/>
        <Switch text="Git"/>
      </ContentSwitcher>
      <SwitchableContent selected={selectedSidePanel === 0}>
        <FileTree
          class="sidePanel {selectedSidePanel == 0 ? 'selectedSidePanel' : ''}"
          bind:this={fileTreeRoot}
          fs={fs}
          path={projectPath}
          label={projectPath}
          on:openFile={handleOpenFile}
          on:deleted={handleDeleted}
          on:renamed={handleRenamed}
          bind:selectedPath>
          <svelte:fragment slot="overflow-menu">
            <OverflowMenuSeparator/>
            <OverflowMenuItem text="Project settings..." on:click={() => projectSettingsOpen = true}/>
          </svelte:fragment>
        </FileTree>
      </SwitchableContent>
      <SwitchableContent selected={selectedSidePanel === 1}>
        <GitPanel {fs} dir={projectPath}/>
      </SwitchableContent>
      {/if}
    </div>
    <div slot="b" class="fill-space" style:display="flex" style:flex-direction="column">
      {#if selectedProjectState}
      <Tabs style="flex: 0 0 auto;" type="container" bind:selected={selectedProjectState.selectedTab} autoWidth>
        {#each selectedProjectState.openPaths as p}
        <FileTabLabel id={p} label={p} pathPrefix={projectPath} on:closeTabId={closeTab}/>
        {/each}
        <svelte:fragment slot="content">
          {#each selectedProjectState.openPaths as p}
          <FileTabContent {fs} filePath={p} />
          {/each}
        </svelte:fragment>
      </Tabs>
      {/if}
    </div>
  </Splitter>
</main>

<SettingsModal bind:settingsOpen />
<ProjectSettingsModal bind:projectSettingsOpen />