<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type { ViewUpdate } from '@codemirror/view';
    import type FS from '@isomorphic-git/lightning-fs';
    import { basicSetup, EditorView } from 'codemirror';
    import { onMount } from 'svelte';

    export let fs: FS;
    export let filePath: string;

    let root: HTMLElement;
    let view: EditorView;

    const theme = EditorView.theme({
        "&": { height: "100%" },
        ".cm-scroller": { overflow: "auto" },
        ".cm-content": { padding: "0.5rem 0" },
    });

    let source = '';

    async function loadFile() {
        source = '';
        try {
            source = await fs.promises.readFile(filePath, 'utf8') as string;
        } catch (e) {}
    }

    async function saveFile(contents: string) {
        await fs.promises.writeFile(filePath, contents, 'utf8');
    }

    loadFile();
    $: saveFile(source);

    onMount(() => {
        view = new EditorView({
            doc: source,
            extensions: [
                basicSetup,
                theme,
                EditorView.updateListener.of((v: ViewUpdate) => {
                if (v.docChanged) {
                    source = view.state.doc.toString();
                }
                }),
            ],
            parent: root,
        });
    });

    $: if (view) {
        const oldText = view.state.doc.toString();
        if (oldText !== source) {
            view.dispatch({
                changes: {
                    from: 0,
                    to: oldText.length,
                    insert: source,
                },
            });
        }
    }
</script>

<div bind:this={root} style="height: 100%;"></div>