<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Modal } from "carbon-components-svelte";
    export let projectSettingsOpen: boolean;
</script>

<style>
    section {
        margin: 1rem 0;
    }
</style>

<Modal
    bind:open={projectSettingsOpen}
    modalHeading="Project Settings"
    passiveModal
>
    <section>
        <h4>...</h4>
    </section>
</Modal>
