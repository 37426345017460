/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

export function ordinal(n: number): string {
    const last = n % 10;
    const lastTwo = n % 100;
    if (last === 1 && lastTwo !== 11) return n + 'st';
    if (last === 2 && lastTwo !== 12) return n + 'nd';
    if (last === 3 && lastTwo !== 13) return n + 'rd';
    return n + 'th';
}

export function plural(base: string, n: number, plural?: string): string {
    return (n === 1) ? base : plural ?? (base + 's');
}
