<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type FS from "@isomorphic-git/lightning-fs";
    import { TabContent } from "carbon-components-svelte";
    import { pathDecompose } from "./fsUtils";
    import SmolFileTab from "./SmolFileTab.svelte";
    import CodeMirror from "./CodeMirror.svelte";

    export let fs: FS;
    export let filePath: string;
    $: extension = pathDecompose(filePath).extension || 'smol';
</script>
<TabContent style="padding: 1rem 0 0 0; flex: 1 1 0%; overflow-y: auto;">
    {#if extension === 'smol'}
    <SmolFileTab fs={fs} filePath={filePath}/>
    {:else}
    <CodeMirror fs={fs} filePath={filePath}/>
    {/if}
</TabContent>
