<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { ParseError, Span, Vm } from '@smolts/interpreter';

    export let source: string;
    export let vm: null | Vm.VM;
    export let parseErrors: ParseError.Suggestion[];

    $: vmFault = vm && vm.state.fault;
    $: parseErrorStrings = parseErrors.map(s => Span.excerptSpan(source, s.span, s.suggestion));
    $: vmFaultStrings = vmFault ? [Span.excerptSpan(source, vmFault.span, vmFault.message)] : [];
    $: failedCheckStrings = (vm?.state.failedChecks ?? []).map(({ span, failure }) => {
      if (failure === false) {
        return Span.excerptSpan(source, span, 'Check body evaluated to false');
      } else {
        const e = Span.excerptSpan(source, failure.span, failure.message);
        return Span.excerptSpan(source, span, 'An error was signalled during execution of the check body:') + '\n\n\t' +
          e.split('\n').join('\n\t');
      }
    });
</script>

<style lang="scss">
  pre {
    padding: 0.5rem;
    font-size: 80%;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .parseError {
    background-color: pink;
    color: black;
  }
  .vmFault {
    background-color: #ff0;
    color: black;
  }
  .failedCheck {
    background-color: #fc4;
    color: black;
  }
</style>

{#each parseErrorStrings as s}
<pre class="parseError"><code>{s}</code></pre>
{/each}
{#each vmFaultStrings as s}
<pre class="vmFault"><code>{s}</code></pre>
{/each}
{#each failedCheckStrings as s}
<pre class="failedCheck"><code>{s}</code></pre>
{/each}