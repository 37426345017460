/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { Span } from "span";
import { Environment, isRecord, Primitive, PrimitiveFunction, Value } from './values';
import { InterpreterError } from './interp';

export function N<Ctx, C extends object, A extends object>(span: Span, v: Value<Ctx, C, A>): number {
    if (typeof v === 'number') return v;
    throw new InterpreterError(span, 'expected a number');
}

export function mkPrim<Ctx, C extends object, A extends object>(
    argv: number,
    fs: Record<string, PrimitiveFunction<Ctx, C, A>>,
): Record<string, Primitive<Ctx, C, A>> {
    const result: Record<string, Primitive<Ctx, C, A>> = {};
    for (const [n, f] of Object.entries(fs)) {
        (f as Primitive<Ctx, C, A>).argv = argv;
        result[n] = (f as Primitive<Ctx, C, A>);
    }
    return result;
}

export function defPrim<V, Ctx, C extends object, A extends object>(
    env: NonNullable<Environment<V, any>>,
    inj: (f: Primitive<Ctx, C, A>) => V,
    argv: number,
    fs: { [name: string]: PrimitiveFunction<Ctx, C, A> },
) {
    for (const [n, f] of Object.entries(fs)) {
        env.scope[n] = inj(mkPrim(argv, { [n]: f })[n]);
    }
}

export function makeTop<V, L, Ctx, C extends object, A extends object>(
    inj: (f: Primitive<Ctx, C, A>) => V,
    next: L,
): NonNullable<Environment<V, L>> {
    const top: Environment<V, L> = { scope: {}, next };
    defPrim(top, inj, 2, { '*': (_ctx, s, a, b) => N(s, a) * N(s, b) });
    defPrim(top, inj, 2, { '/': (_ctx, s, a, b) => {
        const d = N(s, b);
        if (d === 0) throw new InterpreterError(s, 'division by zero');
        return N(s, a) / d;
    }});
    defPrim(top, inj, 2, { '+': (_ctx, s, a, b) => N(s, a) + N(s, b) });
    defPrim(top, inj, 2, { '-': (_ctx, s, a, b) => N(s, a) - N(s, b) });
    defPrim(top, inj, 2, { '<=': (_ctx, s, a, b) => N(s, a) <= N(s, b) });
    defPrim(top, inj, 2, { '=': (_ctx, s, a, b) => N(s, a) === N(s, b) });
    defPrim(top, inj, 2, { 'eq?': (_ctx, _s, a, b) => a === b });
    defPrim(top, inj, 1, { 'error': (_ctx, s, message) => {
        throw new InterpreterError(s, typeof message === 'symbol' ? message.description! : '' + message);
    }});
    defPrim(top, inj, 1, { 'record?': (_ctx, _s, v) => isRecord(v) });
    return top;
}
