<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { onMount } from "svelte";

    export let direction: 'row' | 'column' = 'row';
    export let min = '0%';
    export let max = '100%';
    export let split = '50%';
    export let sliderColor = 'var(--cds-border-strong)';
    export let sliderWidth = '2px';
    export let gap = '0.5rem';

    $: useX = direction === 'row';
    $: actualMax = `calc(${max} - ({sliderWidth} + 2 * {gap}))`;

    let container: HTMLDivElement;
    let panelA: HTMLDivElement;
    let panelB: HTMLDivElement;
    let slider: HTMLDivElement;

    onMount(() => {
        slider.addEventListener('pointerdown', (e: PointerEvent) => {
            const sliderR = slider.getBoundingClientRect();
            const delta = useX ? e.clientX - sliderR.left : e.clientY - sliderR.top;
            const pointerId = e.pointerId;

            function updateSplit(e: PointerEvent) {
                e.stopPropagation();
                e.preventDefault();
                const r = container.getBoundingClientRect();
                const offset = useX ? e.clientX - r.left - delta : e.clientY - r.top - delta;
                const size = useX ? r.width : r.height;
                const ratio = size <= 0 ? 0 : offset / size;
                split = `${Math.min(Math.max(0, ratio), 1) * 100}%`;
                // console.log(e, r, delta, split);
            }

            updateSplit(e);
            slider.setPointerCapture(pointerId);
            window.addEventListener('pointermove', updateSplit, false);

            function done(e: PointerEvent) {
                updateSplit(e);
                slider.releasePointerCapture(pointerId);
                window.removeEventListener('pointermove', updateSplit, false);
                window.removeEventListener('pointerup', done, false);
            }
            window.addEventListener('pointerup', done, false);
        }, { capture: true, passive: false });
    });

    function spuriousClickHandler(e: Event) {
        // Without a click handler on the slider, Chrome Android appears
        // oddly inconsistent about starting and continuing drag actions.
        // It's very strange.
        e.preventDefault();
        e.stopPropagation();
    }
</script>

<style>
    .container {
        display: flex;
    }
</style>

<div class="container fill-space" bind:this={container} style:flex-direction={direction}>
    <div
        class="panel"
        bind:this={panelA}
        style:flex="0 1 {split}"
        style:min-width="{useX ? min : 'auto'}"
        style:max-width="{useX ? actualMax : 'auto'}"
        style:min-height="{useX ? 'auto' : min}"
        style:max-height="{useX ? 'auto' : actualMax}"
        style:overflow-x="{useX ? 'auto' : 'inherit'}"
        style:overflow-y="{useX ? 'inherit' : 'auto'}"
    >
        <slot name="a"/>
    </div>
    <div
        bind:this={slider}
        style:cursor="{useX ? 'col-resize' : 'row-resize'}"
        style:flex="0 0 calc({sliderWidth} + 2 * {gap})"
        class="slider"
        role="presentation"
        on:click={spuriousClickHandler}
    >
        <div
            style:background="{sliderColor}"
            style:width="{useX ? sliderWidth : '100%'}"
            style:height="{useX ? '100%' : sliderWidth}"
            style:margin="{useX ? `0 ${gap}` : `${gap} 0`}"
        ></div>
    </div>
    <div
        class="panel"
        bind:this={panelB}
        style:flex="1 1 0%"
        style:overflow-x="{useX ? 'auto' : 'inherit'}"
        style:overflow-y="{useX ? 'inherit' : 'auto'}"
    >
        <slot name="b"/>
    </div>
</div>
