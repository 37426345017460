/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { ParseError } from "./parseerror";
import { Span } from "./span";

export type Atom = symbol | string | number | boolean;
export type SymbolicExpression = Atom | SymbolicExpression[];

export class AnnotatedSExp {
    span: Span;

    constructor(start: number, end: number, public value: Atom | AnnotatedSExp[]) {
        this.span = {start, end};
    }
}

export function strip(s: AnnotatedSExp): SymbolicExpression {
    return Array.isArray(s.value) ? s.value.map(strip) : s.value;
}

export function read(input: string, readAll = false): AnnotatedSExp {
    let pos = 0;

    function peek<T>(ifEof: T): string | T {
        return input[pos] ?? ifEof;
    }

    function nextCh(start: number, suggestionIfEof: string): string {
        const ch = peek(void 0);
        if (ch === void 0) ParseError.signal(start, pos, suggestionIfEof);
        pos++;
        return ch;
    }

    function isSpace(s: string): boolean {
        return s.length > 0 && ' \t\n\r'.indexOf(s) !== -1;
    }

    function isDigit(s: string): boolean {
        return s.length > 0 && '0123456789'.indexOf(s) !== -1;
    }

    function isDelimiter(s: string): boolean {
        return isSpace(s) || (s.length > 0 && '()[]{}"#;'.indexOf(s) !== -1);
    }

    function skipWhitespace() {
        while (true) {
            let ch = peek(null);
            if (ch === null) {
                return;
            }
            if (isSpace(ch)) {
                pos++;
                continue;
            }
            if (ch === ';') {
                do {
                    pos++;
                    ch = peek(null);
                    if (ch === null) {
                        return;
                    }
                } while ('\r\n'.indexOf(ch) === -1);
                continue;
            }
            return;
        }
    }

    function nextList(start: number, close: string, closeDescription: string): AnnotatedSExp {
        const result: AnnotatedSExp[] = [];
        while (true) {
            skipWhitespace();
            if (peek(void 0) === close) {
                pos++;
                return new AnnotatedSExp(start, pos, result);
            }
            result.push(next(`insert ${closeDescription} ("${close}")`));
        }
    }

    function next(suggestionIfEof: string): AnnotatedSExp {
        skipWhitespace();
        const start = pos;
        let ch = nextCh(start, suggestionIfEof);
        switch (ch) {
            case '(': return nextList(start, ')', 'close parenthesis');
            case '[': return nextList(start, ']', 'close bracket');
            case '{': return nextList(start, '}', 'close brace');
            case '"': {
                let s = '';
                while (true) {
                    ch = nextCh(start, 'close the open string literal');
                    switch (ch) {
                        case '"':
                            return new AnnotatedSExp(start, pos, s);
                        case '\\':
                            ch = nextCh(start, 'supply an escaped string character');
                            // fall through
                        default:
                            s = s + ch;
                            break;
                    }
                }
            }
            case '#':
                switch (nextCh(start, 'finish the special atom')) {
                    case 't': return new AnnotatedSExp(start, pos, true);
                    case 'f': return new AnnotatedSExp(start, pos, false);
                    default: ParseError.signal(start, pos, 'correct the special atom');
                }
            default:
                if (isDigit(ch)) {
                    let digits = ch;
                    while (true) {
                        ch = peek('');
                        if (!isDigit(ch)) break;
                        pos++;
                        digits = digits + ch;
                    }
                    return new AnnotatedSExp(start, pos, parseInt(digits, 10));
                } else if (isDelimiter(ch)) {
                    ParseError.signal(start, pos, 'correct the unexpected delimiter');
                } else {
                    let sym = ch;
                    while (true) {
                        ch = peek(' ');
                        if (isDelimiter(ch)) break;
                        pos++;
                        sym = sym + ch;
                    }
                    return new AnnotatedSExp(start, pos, Symbol.for(sym));
                }
        }
    }

    if (readAll) {
        let items: AnnotatedSExp[] = [];
        skipWhitespace();
        const start = pos;
        while (true) {
            skipWhitespace();
            if (peek(void 0) === void 0) {
                return new AnnotatedSExp(start, pos, items);
            }
            items.push(next('supply further input'));
        }
    } else {
        return next('supply some input');
    }
}
