/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

export type Span = { start: number; end: number; };

export function spanLt(a: Span, b: Span): boolean {
    return (a.start < b.start) || (a.start === b.start && a.end < b.end);
}

export function spanEq(a: Span, b: Span): boolean {
    return (a.start === b.start) && (a.end === b.end);
}

export function advancePosition(source: string, p0: Position): Position {
    const p = { ...p0 };
    if (p.offset < source.length) {
        switch (source.charCodeAt(p.offset++)) {
            case 9: p.column = (((p.column - 1) + 8) & -8) + 1; p.lineOffset++; break;
            case 10: case 13: p.line++; p.column = 1; p.lineOffset = 0; break;
            default: p.column++; p.lineOffset++; break;
        }
    }
    return p;
}

export function skipWs(source: string, p0: Position): Position {
    let p = { ...p0 };
    while (p.offset < source.length && p.line === p0.line) p = advancePosition(source, p);
    return p;
}

export type Position = { offset: number; line: number; column: number; lineOffset: number; };
export function offsetToPosition(source: string, offset: number, start?: Position): Position {
    let result = start ? { ...start } : { offset: 0, line: 1, column: 1, lineOffset: 0 };
    while (result.offset < offset) {
        if (result.offset >= source.length) break;
        result = advancePosition(source, result);
    }
    return result;
}

export function beginningOfLine(p: Position): Position {
    return { offset: p.offset - p.lineOffset, line: p.line, column: 1, lineOffset: 0 };
}

export function endOfLine(source: string, p: Position): Position {
    while (p.offset < source.length) {
        switch (source.charCodeAt(p.offset)) {
            case 10: case 13: return p;
            default: p = advancePosition(source, p); break;
        }
    }
    return p;
}

export function excerptSpan(source: string, span: Span, message?: string): string {
    const p0 = offsetToPosition(source, span.start);
    const p1 = offsetToPosition(source, span.end, p0);
    let result = excerpt(source, p0, p1);
    if (message !== void 0) {
        result = result + `${p0.line}:${p0.column}-${p1.line}:${p1.column}: ${message}\n`;
    }
    return result.trimEnd();
}

export function untabify(s: string): string {
    let result = '';
    for (let i = 0; i < s.length; i++) {
        if (s[i] === '\t') {
            const tabStop = (result.length + 8) & -8;
            result = result + ' '.repeat(tabStop - result.length);
        } else {
            result = result + s[i];
        }
    }
    return result;
}

export function excerpt(source: string, p0: Position, p1 = p0): string {
    if (p1.offset < p0.offset) {
        const tmp = p1;
        p1 = p0;
        p0 = tmp;
    }
    const lineNumberWidth = p1.line.toString().length + 1;
    function formatLine(lineStart: Position): { text: string; end: Position; } {
        const lineEnd = endOfLine(source, lineStart);
        const line = untabify(source.slice(lineStart.offset, lineEnd.offset));
        return {
            text: lineStart.line.toString().padStart(lineNumberWidth) + ': ' + line + '\n',
            end: lineEnd,
        };
    }
    const output: string[] = [];
    if (p0.line === p1.line) {
        output.push(formatLine(beginningOfLine(p0)).text);
        output.push(' '.repeat(p0.column - 1 + lineNumberWidth + 2));
        output.push('^');
        const delta = p1.column - p0.column - 1;
        if (delta > 0) {
            output.push('-'.repeat(delta - 1));
            output.push('^');
        }
        output.push('\n');
    } else {
        let start = beginningOfLine(p0);
        let { text, end } = formatLine(start);
        output.push(' ' + text);
        output.push('+' + '-'.repeat(p0.column - 1 + lineNumberWidth + 2) + '^\n');
        while (start.line < p1.line && start.offset < source.length) {
            start = skipWs(source, end);
            const l = formatLine(start);
            text = l.text;
            end = l.end;
            output.push('|' + text);
        }
        output.push('+' + '-'.repeat(p1.column - 1 + lineNumberWidth + 2 - 1) + '^\n');
    }
    return output.join('');
}
