/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

interface SetConstructor {
    union<T>(... bs: Set<T>[]): Set<T>;
    difference<T>(a: Set<T>, ... bs: Set<T>[]): Set<T>;
    differenceMap<T, R>(f: (t: T) => R, a: Set<T>, ... bs: Set<R>[]): Set<T>;
    intersection<T>(... bs: Set<T>[]): Set<T>;
}

Set.union = <T>(... bs: Set<T>[]) => {
    const r = new Set<T>();
    bs.forEach(b => b.forEach(v => r.add(v)));
    return r;
};

Set.difference = <T>(a: Set<T>, ... bs: Set<T>[]) => {
    return Set.differenceMap(v => v, a, ... bs);
};

Set.differenceMap = <T, R>(f: (t: T) => R, a: Set<T>, ... bs: Set<R>[]) => {
    const r = new Set<T>();
    a.forEach(v => {
        for (const b of bs) if (b.has(f(v))) return;
        r.add(v);
    });
    return r;
};

Set.intersection = <T>(... bs: Set<T>[]) => {
    const r = new Set<T>();
    if (bs.length > 0) {
        bs[0].forEach(v => {
            for (let i = 1; i < bs.length; i++) if (!bs[i].has(v)) return;
            r.add(v);
        });
    }
    return r;
};
