<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Vm } from "@smolts/interpreter";
    import SmolValue from "./SmolValue.svelte";

    export let vm: Vm.VM;
    export let vs: Vm.Value[];
</script>

<span class="v-oparen">[</span>{#each vs as v, i}{#if i > 0}<span class="v-space"> </span>{/if}<SmolValue vm={vm} v={v}/>{/each}<span class="v-cparen">]</span>