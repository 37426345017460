<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    let className = ''; export { className as class };
    export let style = '';
    function mousedown(e: MouseEvent) {
        // This event handler exists *solely* to prevent double-clicking
        // (e.g. `dblclick` event) from selecting text as well.
        if (e.detail > 1) {
            e.preventDefault();
        }
    }
</script>

<style lang="scss">
    a { text-decoration: inherit; color: inherit; }
</style>

<a class="{className}" style="{style}" href="#top" on:click on:mousedown={mousedown} on:dblclick><slot/></a>
