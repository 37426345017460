<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { Vm } from '@smolts/interpreter';
  import SmolStorable from './SmolStorable.svelte';

  export let source: string;
  export let vm: null | Vm.VM;
  $: environments = vm?.allEnvironments() ?? [];
</script>

<style>
  :global(.vm-state-environment-scope) {
    margin-top: 0.5rem;
    padding: 0.333rem;
    border-radius: 0.333rem;
  }
</style>

<h4>Environment</h4>
<div>
    {#if vm}
    {#each environments as e}
    <div><SmolStorable class="vm-state-environment-scope" { ... {vm, source, loc: e.loc, c: e.c, envOpen: true } } /></div>
    {/each}
    {/if}
</div>