/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { Span } from "./span";
import { Environment as GenericEnvironment, Closure, lookup, Scope, Value as GenericValue, Primitive, definedNames, makeFlatClosure, EnvChain, makeDeepClosure, RecType, isRecord } from "./values";
import * as Ast from "./ast";
import { CBody, CDef, CalledFn, CExpr, CProgram, CCheck } from "./contexts";
import { checkArgCount, InterpreterError } from "./interp";
import { defPrim, makeTop, mkPrim, N } from "./topenv";
import { exhaustive } from "./never";

export type Location = number;
export type EnvEntry = [Span, Location];
export type Pointer = {pointer: Location};

export type Value = GenericValue<VM, Pointer, Pointer>;
export type Storable =
    | { type: 'closure', closure: Closure<EnvLink>}
    | { type: 'dynamic-closure', closure: Closure<null> }
    | { type: 'vector', vector: Value[]}
    | { type: 'cell', cell: Value | null}
    | StorableEnv
;
export type StorableEnv = { type: 'env', env: Environment };

export type EnvLink = Location | null;
export type Environment = GenericEnvironment<EnvEntry, EnvLink>;
export type TopEnvironment = GenericEnvironment<Value, null>;

export type Frame = CDef | CCheck | CExpr<Value, EnvLink> | CBody | CProgram;
export type Continuation = Frame[];
export type Store = Storable[];
export type Task = Ast.Def | Ast.Check | Ast.Expr | Ast.Body;

export type Instruction =
    | { type: 'eval', code: Task }
    | { type: 'apply', span: Span, value: Value }
;

export type FailedCheck = { span: Span, failure: InterpreterError | false };

export type State = {
    instruction: Instruction;
    env: EnvLink;
    cont: Continuation;
    fault: InterpreterError | null;
    store: Store;
    failedChecks: FailedCheck[];
};

export function isPointer(v: Value): v is Pointer {
    return typeof v === 'object' && 'pointer' in v;
}

export function makeVMTop(): TopEnvironment {
    const inj: (f: Primitive<VM, Pointer, Pointer>) => Value = f => f;
    const top = makeTop<Value, null, VM, Pointer, Pointer>(inj, null);

    defPrim(top, inj, -1, { 'vec': (vm, _s, ...args) => {
        return vm.withAllocation(alloc => alloc({ type: 'vector', vector: args }));
    } });

    defPrim(top, inj, 3, { 'vec-set!': (vm, s, v, i, w) => {
        if (isPointer(v)) {
            const c = vm.readStore(v.pointer);
            if (c.type == 'vector') {
                const newVec = c.vector.slice();
                newVec[N(s, i)] = w;
                vm.withUpdate(update => update(v.pointer, { type: 'vector', vector: newVec }));
                return w;
            }
        }
        throw new InterpreterError(s, 'expected a vector');
    } });

    defPrim(top, inj, 2, { 'vec-ref': (vm, s, v, i) => {
        if (isPointer(v)) {
            const c = vm.readStore(v.pointer);
            if (c.type === 'vector') {
                return c.vector[N(s, i)];
            }
        }
        throw new InterpreterError(s, 'expected a vector');
    } });

    return top;
}

function alloc(store: Store, v: Storable): { store: Store, loc: Location } {
    return {
        store: [... store, v],
        loc: store.length,
    };
}

export function liveLocations(state: State): Set<Location> {
    // Exercise: use a worklist to avoid stack overflow for complex states

    const seen = new Set<Location>();

    function traceLocation(loc: Location) {
        if (seen.has(loc)) return;
        seen.add(loc);
        const c = state.store[loc];
        switch (c.type) {
            case 'cell': if (c.cell !== null) traceValue(c.cell); break;
            case 'closure': if (c.closure.env !== null) traceLocation(c.closure.env); break;
            case 'dynamic-closure': break;
            case 'env': traceEnv(c.env); break;
            case 'vector': c.vector.forEach(v => traceValue(v)); break;
        }
    }

    function traceValue(v: Value) {
        if (typeof v === 'object') {
            if ('pointer' in v) {
                traceLocation(v.pointer);
            } else if ('record' in v) {
                v.fields.forEach(f => traceValue(f));
            } else {
                exhaustive(v);
            }
        }
    }

    function traceEnv(e: Environment) {
        for (const [_span, loc] of Object.values(e.scope)) {
            traceLocation(loc);
        }
        if (e.next !== null) traceLocation(e.next);
    }

    if (state.instruction.type === 'apply') {
        traceValue(state.instruction.value);
    }

    if (state.env !== null) traceLocation(state.env);

    for (const frame of state.cont) {
        switch (frame.type) {
            case 'scopeBoundary': {
                const f = frame.calledFn?.value;
                if (f !== void 0) traceValue(f);
                frame.history.forEach(h => traceValue(h.value));
                if (frame.env !== null) traceLocation(frame.env);
                break;
            }
            case 'let':
                frame.done.forEach(b => traceValue(b.value));
                break;
            case 'callArgs':
                frame.done.forEach(v => traceValue(v));
                traceValue(frame.fn);
                break;
            case 'set':
            case 'program':
            case 'matchrec':
            case 'defvar':
            case 'cond':
            case 'check':
            case 'callFn':
            case 'body':
            case 'begin':
                break;
            default:
                exhaustive(frame);
        }
    }

    return seen;
}

export class VM {
    state: State;
    top: TopEnvironment;

    elideEmptyScopes = true;
    useFlatClosures = false;
    lexicalClosures = true;

    constructor(top: TopEnvironment, p: Ast.Program) {
        this.top = top;

        this.state = {
            instruction: { type: 'apply', span: p.span, value: 0 },
            env: null,
            cont: [],
            fault: null,
            store: [],
            failedChecks: [],
        };

        const scope: Scope<EnvEntry> = {};
        this.prepareDefinitions(scope, p.terms);
        this.pushEnv(p.span, scope, this.state.env, null, false);
        this.setInstruction(this.state.instruction, p, false);

        this.applyStep(0, this.popContinuation()); // kickstart: get past the initial synthetic 'apply'
    }

    fail(span: Span, message: string) {
        const fault = new InterpreterError(span, message);

        for (let i = this.state.cont.length - 1; i >= 0; i--) {
            const c = this.state.cont[i];
            if (c.type === 'check') {
                this.state = { ... this.state, cont: this.state.cont.slice(0, i) };
                this.recordFailedCheck(c.span, fault);
                this.setInstruction({ type: 'apply', span: c.span, value: Symbol.for('error') });
                return;
            }
        }

        this.state = { ... this.state, fault };
    }

    allocEnv(scope: Scope<EnvEntry>, next: EnvLink): Location {
        return this.withAllocation(alloc => alloc({ type: 'env', env: { scope, next } }).pointer);
    }

    prepareDefinitions(s: Scope<EnvEntry>, terms: Ast.Term[]) {
        definedNames(terms).forEach(v => {
            const cell = this.withAllocation(alloc => alloc({ type: 'cell', cell: null }));
            s[v.name] = [v.span, cell.pointer];
        });
    }

    pushEnv(
        span: Span,
        scope: Scope<EnvEntry>,
        next: Location | null,
        calledFn: CalledFn<Value> | null = null,
        elideEmptyScopes = this.elideEmptyScopes
    ) {
        const isEmpty = Object.keys(scope).length === 0;
        if (elideEmptyScopes && isEmpty && next === this.state.env && calledFn === null) {
            return;
        }
        const cont: Continuation = [ ... this.state.cont, {
            type: 'scopeBoundary',
            span,
            history: [],
            calledFn,
            env: this.state.env,
        } ];
        const env = (elideEmptyScopes && isEmpty) ? next : this.allocEnv(scope, next);
        this.state = { ... this.state, cont, env };
    }

    setInstruction(instruction: Instruction, andPush?: Frame, shouldRecordHistory = true) {
        if (shouldRecordHistory && instruction.type === 'apply') {
            this.recordHistory(instruction.span, instruction.value);
        }
        this.state = {
            ... this.state,
            cont: andPush ? [ ... this.state.cont, andPush ] : this.state.cont,
            instruction,
        };
    }

    popContinuation(): Frame {
        const f = this.state.cont[this.state.cont.length - 1];
        this.state = { ... this.state, cont: this.state.cont.slice(0, this.state.cont.length - 1) };
        return f;
    }

    enterBody(span: Span, body: Ast.Body) {
        const scope: Scope<EnvEntry> = {};
        this.prepareDefinitions(scope, body.terms);
        this.pushEnv(span, scope, this.state.env);
        this.bodyStep(body.terms, body.expr);
    }

    checkArgCount(span: Span, actual: number, expected: number): boolean {
        try {
            checkArgCount(span, actual, expected);
            return true;
        } catch (e) {
            if (e instanceof InterpreterError) {
                this.fail(e.span, e.message);
                return false;
            }
            throw e;
        }
    }

    condStep(span: Span, clauses: Ast.CondClause[], elseClause: Ast.Body | undefined) {
        if (clauses.length > 0) {
            this.setInstruction(
                { type: 'eval', code: clauses[0].test },
                {
                    type: 'cond',
                    span: {
                        start: (clauses[1]?.body.span.start
                            ?? elseClause?.span.start
                            ?? span.end),
                        end: span.end,
                    },
                    body: clauses[0].body,
                    clauses: clauses.slice(1),
                    elseClause,
                });
        } else if (elseClause) {
            this.enterBody(span, elseClause);
        } else {
            this.fail(span, `cond had no clause that matched`);
        }
    }

    beginStep(prelude: Ast.Expr[], expr: Ast.Expr) {
        if (prelude.length === 0) {
            this.setInstruction({ type: 'eval', code: expr });
        } else {
            this.setInstruction(
                { type: 'eval', code: prelude[0] },
                {
                    type: 'begin',
                    span: {
                        start: (prelude[1]?.span.start
                            ?? expr.span.start),
                        end: expr.span.end,
                    },
                    prelude: prelude.slice(1),
                    expr,
                });
        }
    }

    bodyStep(terms: Ast.Term[], expr: Ast.Expr) {
        if (terms.length === 0) {
            this.setInstruction({ type: 'eval', code: expr });
        } else {
            this.setInstruction(
                { type: 'eval', code: terms[0] },
                {
                    type: 'body',
                    span: {
                        start: (terms[1]?.span.start
                            ?? expr.span.start),
                        end: expr.span.end,
                    },
                    terms: terms.slice(1),
                    expr,
                });
        }
    }

    withAllocation<R>(f: (alloc: (v: Storable) => Pointer) => R): R {
        let store = this.state.store;
        const result = f(v => {
            const r = alloc(store, v);
            store = r.store;
            return {pointer: r.loc};
        });
        this.state = { ... this.state, store };
        return result;
    }

    readStore(loc: Location): Storable {
        return this.state.store[loc];
    }

    withUpdate(f: (update: (loc: Location, v: Storable) => void) => void) {
        let store = this.state.store.slice();
        f((loc, v) => store[loc] = v);
        this.state = { ... this.state, store };
    }

    callStep(span: Span, fnName: string | null, fn: Value, done: Value[], pending: Ast.Expr[]) {
        if (pending.length === 0) {
            if (typeof fn === 'function') {
                if (!this.checkArgCount(span, done.length, fn.argv)) return;
                let result;
                try {
                    result = fn(this, span, ... done);
                } catch (e) {
                    if (e instanceof InterpreterError) {
                        this.fail(e.span, e.message);
                        return;
                    }
                    throw e;
                }
                this.setInstruction({ type: 'apply', span, value: result });
                return;
            }

            if (isPointer(fn)) {
                const c = this.readStore(fn.pointer);
                if (c.type === 'closure' || c.type === 'dynamic-closure') {
                    if (!this.checkArgCount(span, done.length, c.closure.formals.length)) return;
                    const scope: Scope<EnvEntry> = {};
                    this.withAllocation(alloc => {
                        done.forEach((v, i) => {
                            const f = c.closure.formals[i];
                            scope[f.name] = [f.span, alloc({ type: 'cell', cell: v }).pointer];
                        });
                    });
                    const calledFn = { name: fnName, value: fn };
                    this.pushEnv(span, scope, c.type === 'closure' ? c.closure.env : this.state.env, calledFn);
                    this.enterBody(span, c.closure.body);
                    return;
                }
            }

            this.fail(span, 'attempted to call something not callable');
            return;
        } else {
            this.setInstruction(
                { type: 'eval', code: pending[0] },
                { type: 'callArgs', span, fnName, fn, done, pending: pending.slice(1) });
            return;
        }
    }

    recordHistory(span: Span, value: Value) {
        const cont = this.state.cont.slice();
        for (let i = cont.length - 1; i >= 0; i--) {
            const frame = cont[i];
            if (frame.type === 'scopeBoundary') {
                cont[i] = { ... frame, history: [ ... frame.history, { span, value } ] };
                break;
            }
        }
        this.state = { ... this.state, cont };
    }

    isApply(): boolean {
        return !this.isStopped() && this.state.instruction.type === 'apply';
    }

    /// Detect penultimate step; useful for showing the final state of the main
    /// program just before completion
    isPenultimateStep(): boolean {
        if (this.isStopped()) return false;
        if (this.state.instruction.type !== 'apply') return false;
        if (this.state.cont.length !== 1) return false;
        if (this.state.cont[0].type !== 'scopeBoundary' /* peculiar! */) return false;
        return true;
    }

    isStopped(): boolean {
        if (this.state.fault !== null) {
            return true;
        }

        if (this.state.instruction.type === 'apply' && this.state.cont.length === 0) {
            return true;
        }

        return false;
    }

    activeSpan(): Span {
        switch (this.state.instruction.type) {
            case 'eval': return this.state.instruction.code.span;
            case 'apply': return this.state.instruction.span;
        }
    }

    followEnvLink(l: Location): StorableEnv {
        const c = this.readStore(l);
        if (c.type !== 'env') throw new Error("Internal error: bad environment");
        return c;
    }

    allEnvironments(): { loc: Location, c: StorableEnv }[] {
        const environments = [];
        let loc = this.state.env;
        while (loc !== null) {
            const c = this.followEnvLink(loc);
            environments.push({ loc, c });
            loc = c.env.next;
        }
        return environments;
    }

    allScopes(): Scope<EnvEntry>[] {
        return this.allEnvironments().map(e => e.c.env.scope);
    }

    readonly envChain: EnvChain<EnvEntry, EnvLink> = l => l === null ? null : this.followEnvLink(l).env;

    makeClosure(formals: Ast.Var[], body: Ast.Body): Pointer {
        if (this.lexicalClosures) {
            const closure = this.useFlatClosures
                ? makeFlatClosure(this.envChain, this.envChain(this.state.env), s => this.allocEnv(s, null), formals, body)
                : makeDeepClosure(this.state.env, formals, body);
            return this.withAllocation(alloc => alloc({ type: 'closure', closure }));
        } else {
            const closure: Closure<null> = { env: null, formals, body };
            return this.withAllocation(alloc => alloc({ type: 'dynamic-closure', closure }));
        }
    }

    evalStep(e: Task) {
        switch (e.type) {
            case 'defvar':
                this.setInstruction(
                    { type: 'eval', code: e.expr },
                    { type: 'defvar', span: e.span, name: e.name });
                return;

            case 'deffun': {
                const clo = this.makeClosure(e.formals, e.body);
                this.initializeBinding(this.state.env!, e.name, clo);
                this.setInstruction({ type: 'apply', span: e.span, value: clo });
                return;
            }

            case 'defrec': {
                const ty: RecType = { name: Symbol.for(e.name.name), fields: e.fields.map(f => Symbol.for(f.name)) };
                const n = `〈${e.name.name}〉`;
                const ctor = mkPrim(e.fields.length, { [n]: (_vm: VM, _s: Span, ... args: Value[]) => ({ record: ty, fields: args }) })[n];
                this.initializeBinding(this.state.env!, e.name, ctor);
                this.setInstruction({ type: 'apply', span: e.span, value: ctor });
                return;
            }

            case 'check':
                this.setInstruction(
                    { type: 'eval', code: e.body },
                    { type: 'check', span: e.span });
                return;

            case 'const':
                this.setInstruction({ type: 'apply', span: e.span, value: e.literal }, void 0, false);
                return;

            case 'var': {
                const loc = lookup(this.envChain, this.envChain(this.state.env), e.name);
                if (loc === 'missing') {
                    const loc = lookup((l: TopEnvironment | null) => l, this.top, e.name);
                    if (typeof loc === 'string') {
                        this.fail(e.span, `Variable ${e.name} ${loc}`);
                    } else {
                        this.setInstruction({
                            type: 'apply',
                            span: e.span,
                            value: loc.value,
                        });
                    }
                } else {
                    const c = this.readStore(loc.value[1]);
                    if (c.type !== 'cell') throw new Error("Internal error: non-cell for variable");
                    if (c.cell === null) {
                        this.fail(e.span, `Variable ${e.name} uninitialized`);
                    } else {
                        this.setInstruction({
                            type: 'apply',
                            span: e.span,
                            value: c.cell,
                        });
                    }
                }
                return;
            }

            case 'set':
                this.setInstruction(
                    { type: 'eval', code: e.expr },
                    { type: 'set', span: e.span, varName: e.varName });
                return;

            case 'cond':
                this.condStep(e.span, e.clauses, e.elseClause);
                return;

            case 'matchrec':
                this.setInstruction(
                    { type: 'eval', code: e.expr },
                    { type: 'matchrec', span: e.span, clauses: e.clauses, elseClause: e.elseClause });
                return;

            case 'lambda': {
                const clo = this.makeClosure(e.formals, e.body);
                this.setInstruction({ type: 'apply', span: e.span, value: clo });
                return;
            }

            case 'let':
                if (e.bindings.length === 0) {
                    this.enterBody(e.span, e.body);
                } else {
                    this.setInstruction(
                        { type: 'eval', code: e.bindings[0].expr },
                        {
                            type: 'let',
                            span: {
                                start: (e.bindings[1]?.span.start
                                    ?? e.body.span.start),
                                end: e.span.end,
                            },
                            done: [],
                            currentVarName: e.bindings[0].varName,
                            pending: e.bindings.slice(1),
                            body: e.body,
                        });
                }
                return;

            case 'begin':
                this.beginStep(e.prelude, e.expr);
                return;

            case 'call':
                let fnName: string | null = null;
                if (e.operator.type === 'var') {
                    fnName = e.operator.name;
                }
                this.setInstruction(
                    { type: 'eval', code: e.operator },
                    { type: 'callFn', span: e.span, fnName, actuals: e.actuals });
                return;

            case 'body':
                this.bodyStep(e.terms, e.expr);
                return;

            default:
                exhaustive(e);
        }
    }

    initializeBinding(env: NonNullable<EnvLink>, varName: Ast.Var, v: Value) {
        const c = this.readStore(env);
        if (c.type !== 'env') throw new Error("Internal error: bad environment");
        const [_span, loc] = c.env.scope[varName.name];
        this.withUpdate(update => update(loc, { type: 'cell', cell: v }));
    }

    recordFailedCheck(span: Span, failure: InterpreterError | false) {
        this.state = { ... this.state, failedChecks: [... this.state.failedChecks, { span, failure }] };
    }

    applyStep(v: Value, c: Frame) {
        switch (c.type) {
            case 'defvar':
                this.initializeBinding(this.state.env!, c.name, v);
                this.setInstruction({ type: 'apply', span: c.span, value: v });
                return;

            case 'check':
                if (v === false) this.recordFailedCheck(c.span, false);
                this.setInstruction({ type: 'apply', span: c.span, value: Symbol.for(v !== false ? 'pass' : 'fail') });
                return;

            case 'set': {
                const loc = lookup(this.envChain, this.envChain(this.state.env), c.varName.name);
                if (loc === 'missing') {
                    this.fail(c.span, `Variable ${c.varName.name} missing`);
                } else {
                    this.withUpdate(update => update(loc.value[1], { type: 'cell', cell: v }));
                    this.setInstruction({ type: 'apply', span: c.span, value: v });
                }
                return;
            }

            case 'cond':
                if (v !== false) {
                    this.enterBody(c.span, c.body);
                } else {
                    this.condStep(c.span, c.clauses, c.elseClause);
                }
                return;

            case 'matchrec':
                if (isRecord(v)) {
                    for (const m of c.clauses) {
                        if (v.record.name.description! === m.typeName.name
                            && v.fields.length === m.fieldNames.length)
                        {
                            const scope: Scope<EnvEntry> = {};
                            this.withAllocation(alloc => {
                                m.fieldNames.forEach((n, i) =>
                                    scope[n.name] = [
                                        n.span,
                                        alloc({ type: 'cell', cell: v.fields[i] }).pointer,
                                    ]);
                            });
                            this.pushEnv(m.span, scope, this.state.env);
                            this.enterBody(m.span, m.body);
                            return;
                        }
                    }
                }
                if (c.elseClause) {
                    this.enterBody(c.elseClause.span, c.elseClause);
                } else {
                    this.fail(c.span, `matchrec had no clause that matched`);
                }
                return;

            case 'let': {
                const done = [... c.done, { varName: c.currentVarName, value: v }];
                if (c.pending.length === 0) {
                    const scope: Scope<EnvEntry> = {};
                    this.withAllocation(alloc => {
                        done.forEach(({ varName, value }) =>
                            scope[varName.name] = [
                                varName.span,
                                alloc({ type: 'cell', cell: value }).pointer,
                            ]);
                    });
                    this.pushEnv(c.span, scope, this.state.env);
                    this.enterBody(c.span, c.body);
                } else {
                    this.setInstruction(
                        { type: 'eval', code: c.pending[0].expr },
                        {
                            type: 'let',
                            span: {
                                start: (c.pending[1]?.span.start
                                    ?? c.body.span.start),
                                end: c.span.end,
                            },
                            done,
                            currentVarName: c.pending[0].varName,
                            pending: c.pending.slice(1),
                            body: c.body,
                        });
                }
                return;
            }

            case 'begin':
                this.beginStep(c.prelude, c.expr);
                return;

            case 'callFn':
                this.callStep(c.span, c.fnName, v, [], c.actuals);
                return;

            case 'callArgs':
                this.callStep(c.span, c.fnName, c.fn, [... c.done, v], c.pending);
                return;

            case 'scopeBoundary':
                this.recordHistory(c.span, v);
                this.state = {
                    ... this.state,
                    env: c.env,
                    instruction: { type: 'apply', span: c.span, value: v },
                };
                return;

            case 'body':
                this.bodyStep(c.terms, c.expr);
                return;

            case 'program':
                if (c.terms.length > 0) {
                    this.setInstruction(
                        { type: 'eval', code: c.terms[0] },
                        {
                            type: 'program',
                            span: {
                                start: (c.terms[1]?.span.start
                                    ?? c.span.end),
                                end: c.span.end,
                            },
                            terms: c.terms.slice(1),
                        });
                }
                return;

            default:
                exhaustive(c);
        }
    }

    step() {
        if (this.isStopped()) return;

        switch (this.state.instruction.type) {
            case 'eval': {
                this.evalStep(this.state.instruction.code);
                return;
            }
            case 'apply': {
                const v = this.state.instruction.value;
                const c = this.popContinuation();
                this.applyStep(v, c);
                return;
            }
        }
    }
}
