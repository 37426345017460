<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    export let selected: boolean;
</script>
<style>
    div {
        flex: 0 0 0%;
        overflow-y: auto;
    }
    div.selected {
        flex: 1 1 0%;
    }
</style>
<div class:selected={selected}>
    <slot/>
</div>
