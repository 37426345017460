<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { onMount } from 'svelte';
  import { basicSetup, } from "codemirror";
  import { EditorView, Decoration, ViewUpdate, WidgetType, GutterMarker, gutter, keymap } from "@codemirror/view";
  import type { DecorationSet } from "@codemirror/view";
  import { Range, RangeSet, StateField, StateEffect } from "@codemirror/state";
  import { indentWithTab } from "@codemirror/commands";
  import { LRLanguage, indentNodeProp, foldNodeProp, foldInside, delimitedIndent } from "@codemirror/language";
  import { styleTags, tags as t } from "@lezer/highlight";
  import { parser } from "./syntax.js";

  import { ParseError, Span, Vm } from "@smolts/interpreter";
  import SmolValue from 'SmolValue.svelte';
    import { smolSettings } from 'smolSettings.js';

  //---------------------------------------------------------------------------
  // Properties

  export let source: string;
  export let cursorPos: number;
  export let parseErrors: ParseError.Suggestion[];
  export let contextFrameSpan: null | Span.Span;
  export let vm: null | Vm.VM;

  export let style = '';

  let view: EditorView;
  let root: HTMLDivElement;

  //const dispatch = createEventDispatcher();

  //---------------------------------------------------------------------------

  const activeCodeEvalMark = Decoration.mark({ class: "smol-active-code-eval" });
  const activeCodeApplyMark = Decoration.mark({ class: "smol-active-code-apply" });
  const parseErrorMark = Decoration.mark({ class: "smol-parse-error" });
  const interpreterErrorMark = Decoration.mark({ class: "smol-interpreter-error" });
  const contextSpanMark = Decoration.mark({ class: "smol-context-span" });

  const parseErrorGutterMarker = new class extends GutterMarker {
    toDOM() {
      const n = document.createElement('span');
      n.className = 'smol-error-gutter-symbol';
      n.innerText = '⚠';
      return n;
    }
  };

  const updateVM = StateEffect.define<Vm.VM | null>();
  const updateParseErrors = StateEffect.define<ParseError.Suggestion[]>();
  const updateContextSpan = StateEffect.define<Span.Span | null>();

  class AvailableValueWidget extends WidgetType {
    constructor(public renderedValue: HTMLElement) {
      super();
    }

    toDOM(): HTMLElement {
      return this.renderedValue;
    }
  }

  const theme = EditorView.theme({
    "&": { height: "100%" },
    ".cm-scroller": { overflow: "auto" },
    ".cm-content": { padding: "0.5rem 0" },
  });

  const smolLanguage = LRLanguage.define({
    parser: parser.configure({
      props: [
        indentNodeProp.add({
          ParenList: delimitedIndent({ closing: ")", align: false }),
          BrackList: delimitedIndent({ closing: "]", align: false }),
          BraceList: delimitedIndent({ closing: "}", align: false }),
        }),
        foldNodeProp.add({
          ParenList: foldInside,
          BrackList: foldInside,
          BraceList: foldInside,
        }),
        styleTags({
          Number: t.literal,
          Identifier: t.variableName,
          Boolean: t.bool,
          String: t.string,
          LineComment: t.lineComment,
          "defvar deffun defrec": t.definitionKeyword,
          '"set!" lambda': t.operatorKeyword,
          "if cond else begin matchrec check": t.controlKeyword,
          "let": t.definitionKeyword,
          "( )": t.paren,
          "[ ]": t.paren,
          "{ }": t.paren,
        }),
      ],
    }),
    languageData: {
      commentTokens: { line: ";;" },
    },
  });

  function generalSpanRange(d: Decoration, span: Span.Span, limit: number): Range<Decoration> {
    const [rangeStart, rangeEnd] =
          span.start < span.end ? [span.start, span.end] :
          span.end >= limit ? [span.start - 1, span.end] :
          [span.start, span.end + 1];
    return d.range(rangeStart, rangeEnd);
  }

  function spanLines(d: Decoration, span: Span.Span): Range<Decoration>[] {
    const sp = Span.offsetToPosition(source, span.start);
    const ep = Span.offsetToPosition(source, span.end);
    const startLinePos = sp.offset - sp.lineOffset;
    const endLinePos = ep.offset - ep.lineOffset;
    const marks = [];
    for (let i = startLinePos; i <= endLinePos; i++) {
      marks.push(d.range(i));
    }
    return marks;
  }

  function updateAvailableValues(vm: Vm.VM): Range<Decoration>[] {
    const availableValues: { [pos: number]: Vm.Value } = {};

    const frames = vm.state.cont.slice();
    frames.reverse();
    frames.forEach(frame => {
      if (frame.type === 'scopeBoundary') {
        frame.history.forEach(({ span, value }) => {
          if (!(span.end in availableValues)) {
            availableValues[span.end] = value;
          }
        });
      }
    });

    vm.allScopes().forEach(scope => {
      for (const entry of Object.values(scope)) {
        if (entry === null) continue;
        const [span, valueLoc] = entry;
        const c = vm.readStore(valueLoc);
        if (c.type !== 'cell') throw new Error("Internal error: non-cell in scope");
        if (c.cell === null) continue;
        if (!(span.end in availableValues)) {
          availableValues[span.end] = c.cell;
        }
      }
    });

    function callableType(value: Vm.Value): 'primitive' | 'closure' | 'dynamic-closure' | null {
      if (typeof value === 'function') return 'primitive';
      if (typeof value !== 'object' || !('pointer' in value)) return null;
      const c = vm.readStore(value.pointer);
      if (c.type === 'closure') return 'closure';
      if (c.type === 'dynamic-closure') return 'dynamic-closure';
      return null;
    }

    return Object.entries(availableValues).map(([p, value]) => {
      const n = document.createElement('span');
      n.classList.add('smol-available-value');
      n.classList.add('smol-value');
      const ty = callableType(value);
      if (ty !== null && $smolSettings.hideFunctions) {
        n.classList.add('smol-hidden-function');
        n.classList.add('v-' + ty);
        n.title = ty + ' ' + (typeof value === 'function' ? value.name : `#${(value as Vm.Pointer).pointer}`);
      } else {
        n.classList.add('smol-shown-value');
        new SmolValue({ target: n, props: { vm, v: value }});
      }
      const pos = parseInt(p, 10);
      return Decoration.widget({ widget: new AvailableValueWidget(n) }).range(pos, pos);
    });
  }

  const vmStateField = StateField.define<DecorationSet>({
    create() {
      return Decoration.none;
    },
    update(oldMarks, tr) {
      oldMarks = oldMarks.map(tr.changes);
      const marks: Range<Decoration>[] = [];

      function spanRange(d: Decoration, span: Span.Span): Range<Decoration> {
        return generalSpanRange(d, span, tr.newDoc.length);
      }

      let updated = false;
      for (const e of tr.effects) {
        if (e.is(updateVM)) {
          updated = true;
          const vm = e.value;
          if (vm !== null) {
            if (!vm.isStopped()) {
              marks.push(spanRange(vm.isApply() ? activeCodeApplyMark : activeCodeEvalMark, vm.activeSpan()));
              marks.push(... spanLines(Decoration.line({ class: 'smol-active-code-line' }), vm.activeSpan()));
            }

            if (vm.state.fault) {
              marks.push(spanRange(interpreterErrorMark, vm.state.fault.span));
            }

            marks.push(... updateAvailableValues(vm));
          }
        }
      }
      return updated ? Decoration.set(marks, true) : oldMarks;
    },
    provide: f => EditorView.decorations.from(f)
  });

  const parseErrorLineState = StateField.define<RangeSet<GutterMarker>>({
    create() {
      return RangeSet.empty;
    },
    update(oldMarks, tr) {
      oldMarks = oldMarks.map(tr.changes);
      let updated = false;
      const positions = new Set<number>();
      for (const e of tr.effects) {
        if (e.is(updateParseErrors)) {
          updated = true;
          for (const s of e.value) {
            const span = s.span;
            const p = Span.offsetToPosition(source, span.start);
            positions.add(p.offset - p.lineOffset);
          }
        }
      }
      return updated ? RangeSet.of(Array.from(positions).map(p => parseErrorGutterMarker.range(p))) : oldMarks;
    }
  });

  const parseErrorRangeState = StateField.define<DecorationSet>({
    create() {
      return Decoration.none;
    },
    update(oldMarks, tr) {
      oldMarks = oldMarks.map(tr.changes);
      let updated = false;
      const marks: Range<Decoration>[] = [];
      for (const e of tr.effects) {
        if (e.is(updateParseErrors)) {
          updated = true;
          for (const s of e.value) {
            marks.push(generalSpanRange(parseErrorMark, s.span, tr.newDoc.length));
          }
        }
      }
      return updated ? Decoration.set(marks) : oldMarks;
    },
    provide: f => EditorView.decorations.from(f)
  });

   const contextSpanMarkField = StateField.define<DecorationSet>({
    create() {
      return Decoration.none;
    },
    update(oldMarks, tr) {
      oldMarks = oldMarks.map(tr.changes);
      let updated = false;
      const marks: Range<Decoration>[] = [];
      for (const e of tr.effects) {
        if (e.is(updateContextSpan)) {
          updated = true;
          if (e.value !== null) {
            marks.push(generalSpanRange(contextSpanMark, e.value, tr.newDoc.length));
          }
        }
      }
      return updated ? Decoration.set(marks) : oldMarks;
    },
    provide: f => EditorView.decorations.from(f)
  });

  onMount(() => {
    view = new EditorView({
      doc: source,
      extensions: [
        basicSetup,
        theme,
        keymap.of([indentWithTab]),
        smolLanguage,
        vmStateField,
        parseErrorLineState,
        parseErrorRangeState,
        contextSpanMarkField,
        EditorView.updateListener.of((v: ViewUpdate) => {
          if (v.docChanged) {
            source = view.state.doc.toString();
          }
          if (v.selectionSet) {
            cursorPos = view.state.selection.main.from;
          }
        }),
        gutter({
          class: 'smol-error-gutter',
          markers: v => v.state.field(parseErrorLineState),
          initialSpacer: () => parseErrorGutterMarker,
        }),
      ],
      parent: root,
    });
  });

  $: view?.dispatch({ effects: [updateVM.of(vm)] });

  $: if (view) {
    const oldText = view.state.doc.toString();
    if (oldText !== source) {
      view.dispatch({
        changes: {
          from: 0,
          to: oldText.length,
          insert: source,
        },
      });
    }
  }

  $: view?.dispatch({effects: [updateParseErrors.of(parseErrors)]});
  $: view?.dispatch({effects: [updateContextSpan.of(contextFrameSpan)]});
</script>

<style lang="scss">
  @import "smol.css";
</style>

<div bind:this={root} style="{style}"></div>