<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { Contexts, exhaustive, Vm } from '@smolts/interpreter';
  import { ordinal, plural } from './english.js';
  import SmolValue from './SmolValue.svelte';
  import VmStateControlFrameLine from './VmStateControlFrameLine.svelte';
  import SmolLocation from './SmolLocation.svelte';
  
  export let vm: Vm.VM;
  export let frame: Vm.Frame;
  export let selectedFrame: Vm.Frame | null = null;

  function totalArgs(frame: Contexts.CCallArgs<Vm.Value, Vm.EnvLink>): number {
    return frame.done.length + frame.pending.length + 1;
  }
</script>

{#if frame.type === 'program'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to run the rest of the program</VmStateControlFrameLine>
{:else if frame.type === 'defvar'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to define the variable <code>{frame.name.name}</code></VmStateControlFrameLine>
{:else if frame.type === 'check'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to complete a test case</VmStateControlFrameLine>
{:else if frame.type === 'scopeBoundary'}
    {#if frame.calledFn !== null}
        {#if frame.calledFn.name === null}
            <VmStateControlFrameLine {frame} bind:selectedFrame>
                in a call to anonymous function <SmolValue {vm} v={frame.calledFn.value}/>
            </VmStateControlFrameLine>
        {:else}
            <VmStateControlFrameLine {frame} bind:selectedFrame>
                in a call to function <code>{frame.calledFn.name}</code> <SmolValue {vm} v={frame.calledFn.value}/>
            </VmStateControlFrameLine>
            <VmStateControlFrameLine {frame} bind:selectedFrame>
                in environment <i>env</i> <SmolLocation loc={frame.env}/>
            </VmStateControlFrameLine>
        {/if}
    {/if}
{:else if frame.type === 'callFn'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to figure out which function to call</VmStateControlFrameLine>
{:else if frame.type === 'callArgs'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to compute the {ordinal(frame.done.length + 1)} of
    {totalArgs(frame)} {plural('argument', totalArgs(frame))}</VmStateControlFrameLine>
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to call
    {#if frame.fnName === null}
    function
    {:else}
    <code>{frame.fnName}</code>
    {/if}
    <SmolValue {vm} v={frame.fn}/></VmStateControlFrameLine>
{:else if frame.type === 'begin'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to finish the sequence of instructions</VmStateControlFrameLine>
{:else if frame.type === 'body'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to execute the rest of the block</VmStateControlFrameLine>
{:else if frame.type === 'cond'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to decide which branch to follow</VmStateControlFrameLine>
{:else if frame.type === 'matchrec'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to decide which record type matches</VmStateControlFrameLine>
{:else if frame.type === 'let'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to initialize the variable <code>{frame.currentVarName.name}</code></VmStateControlFrameLine>
{:else if frame.type === 'set'}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    in order to update the variable <code>{frame.varName.name}</VmStateControlFrameLine>
{:else if exhaustive(frame)}
<VmStateControlFrameLine {frame} bind:selectedFrame>
    ???</VmStateControlFrameLine>
{/if}