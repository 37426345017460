<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type { Vm } from "@smolts/interpreter";
    import ClickableText from "./ClickableText.svelte";

    export let frame: Vm.Frame;
    export let selectedFrame: Vm.Frame | null;
    $: isSelected = selectedFrame === frame;

    function click(e: Event) {
        e.preventDefault();
        selectedFrame = isSelected ? null : frame;
    }
</script>

<style lang="scss">
    .smol-selected-frame { background-color: var(--active-code-color); }
</style>

<li class:smol-selected-frame={isSelected}><ClickableText on:click={click}><slot/></ClickableText></li>
