<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Vm } from '@smolts/interpreter';
    import SmolStorable from './SmolStorable.svelte';
    import { smolSettings } from 'smolSettings';

    export let source: string;
    export let vm: null | Vm.VM;

    $: live = (vm && $smolSettings.hideGarbage && Vm.liveLocations(vm.state)) || null;
</script>

<style lang="scss">
    table {
        width: 100%;
    }
    tr.heading > th {
        font-weight: bold;
    }
    tr.heading > th:nth-child(2) {
        text-align: left;
    }
    tr.entry {
        th, td { padding-top: 0.333rem; }
    }
    th {
        text-align: right;
        vertical-align: middle;
        padding-right: 4px;
    }
    td {
        text-align: left;
        vertical-align: top;
    }

    :global(.vm-state-store-entry) {
        padding: 0.333rem;
        border-radius: 0.333rem;
    }
</style>

<div>
    <h4>Store</h4>
    <table>
        {#if vm}
        <tr class="heading"><th>loc#</th><th>contents</th></tr>
        {#each vm.state.store as c, loc}
            {#if live === null || live.has(loc)}
            {#if !($smolSettings.hideCells && c.type === 'cell')}
            <tr class="entry"><th>{loc}</th><td><SmolStorable class="vm-state-store-entry" { ... { vm, source, loc, c } }/></td></tr>
            {/if}
            {/if}
        {/each}
        {/if}
    </table>
</div>