<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import SmolEditor from './SmolEditor.svelte';
    import VmControls from './VmControls.svelte';
    import VmState from './VmState.svelte';
    import { ParseError, Span, Vm } from "@smolts/interpreter";
    import { smolSettings } from './smolSettings.js';
    import Splitter from './Splitter.svelte';

    export let source = ';; placeholder';

    let vm: null | Vm.VM = null;
    let parseErrors: ParseError.Suggestion[] = [];
    let contextFrameSpan: null | Span.Span = null;
    let cursorPos: number;
</script>

<style lang="scss">
    @import "smol.css";
    .container {
        display: flex;
        flex-direction: column;
    }
</style>

<section class="container fill-space">
    <div style:padding="0" style:padding-bottom="4px" style:flex="0 0 auto">
        <VmControls
            bind:source
            bind:skipApplyStates={$smolSettings.skipApplyStates}
            bind:skipBoringStates={$smolSettings.skipBoringStates}
            bind:vm
            bind:parseErrors
            bind:cursorPos
        />
    </div>
    <div style:flex="1 1 0%" style:overflow-y="auto">
        <Splitter direction="column" split="calc(100% - 20em)">
            <SmolEditor
                slot="a"
                style="height: 100%;"
                bind:source
                bind:vm
                bind:parseErrors
                bind:contextFrameSpan
                bind:cursorPos
            />
            <VmState
                slot="b"
                bind:source
                bind:vm
                bind:parseErrors
                bind:contextFrameSpan
            />
        </Splitter>
    </div>
</section>
