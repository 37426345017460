<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<style>
    hr {
        width: 100%;
        border: 0;
        border-bottom: solid 1px var(--cds-border-subtle);
    }
</style>

<hr>
