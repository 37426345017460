<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type FS from "@isomorphic-git/lightning-fs";
    import { maybeStat, pathJoin } from "./fsUtils.js";
    import git from 'isomorphic-git';
    import { Button } from "carbon-components-svelte";
    import { CloudDownload, CloudUpload } from "carbon-icons-svelte";

    export let fs: FS;
    export let dir: string;

    async function createGitRepositoryIfNecessary() {
        if (await maybeStat(fs, pathJoin(dir, '.git')) === null) {
            await git.init({ fs, dir, defaultBranch: 'main' });
        }
    }
    createGitRepositoryIfNecessary();

    let status: [string, 0 | 1, 0 | 1 | 2, 0 | 1 | 2 | 3][] = [];
    async function refresh() {
        status = await git.statusMatrix({ fs, dir });
    }
    refresh();
</script>

<style lang="scss">
    table.status {
        width: 100%;
        td {
            padding: 4px 0 0 4px;
        }
        td:first-child {
            padding-left: 0px;
        }
    }
</style>

<div style:padding="4px">
    <Button size="small" kind="tertiary" on:click={refresh}>Refresh</Button>
    <Button size="small" kind="tertiary">Commit</Button>
    <Button size="small" kind="tertiary"><CloudDownload/></Button>
    <Button size="small" kind="tertiary"><CloudUpload/></Button>
</div>
<div style:padding="4px">
    <h5>Changes to commit</h5>
    <table class="status">
        {#each status as [f, _head, workdir, _stage]}
        <tr><td>{f}</td><td>{workdir === 0 ? '(deleted)' : ''}</td></tr>
        {/each}
    </table>
</div>
