<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type FS from "@isomorphic-git/lightning-fs";
    import { Select, SelectItem, Button } from 'carbon-components-svelte';
    import { Add, Settings } from 'carbon-icons-svelte';
    import { isFsError, pathJoin } from "./fsUtils.js";
    import NewProjectModal, { type Spec } from "./NewProjectModal.svelte";

    export let fs: FS;
    export let projects: string[] = [];
    export let selectedProject: string | null = null;
    export let projectPath: string | null = null;
    $: projectPath = selectedProject && pathJoin(PROJECTS, selectedProject);

    export let style = '';
    export let settingsOpen: boolean;

    let newProject: NewProjectModal;
    let newProjectSpec: Spec;

    const PROJECTS = '/projects';

    export async function rescanProjects() {
        try {
            projects = await fs.promises.readdir(PROJECTS);
        } catch (e) {
            if (isFsError(e) && e.code === 'ENOENT') {
                await fs.promises.mkdir(PROJECTS);
                projects = [];
            } else {
                throw e;
            }
        }
        projects.sort();
        if (selectedProject !== null && projects.indexOf(selectedProject) === -1) {
            selectedProject = null;
        }
    }

    async function createProject() {
        newProject.closeModal();
        switch (newProjectSpec.type) {
            case 'none':
                return;
            case 'local':
                if (!newProjectSpec.projectName) return;
                try {
                    await fs.promises.mkdir(PROJECTS);
                } catch (_e) {}
                await fs.promises.mkdir(pathJoin(PROJECTS, newProjectSpec.projectName));
                await rescanProjects();
                selectedProject = newProjectSpec.projectName;
                break;
            case 'clone':
                if (newProjectSpec.projectName === '') {
                    newProjectSpec.projectName = newProject.defaultProjectName(newProjectSpec);
                }
                /* fall through */
            default:
                alert(JSON.stringify(newProjectSpec));
        }
    }

    function selectProject(e: Event) {
        selectedProject = (e.target as HTMLSelectElement).value || null;
    }

    rescanProjects();
</script>

<style>
    @import "App.css";
    div {
        display: flex;
        align-items: end;
    }
</style>

<div class="inline-panel-buttons" style="{style}">
    <Select
        size="sm"
        selected={selectedProject ?? void 0}
        on:change={selectProject}
    >
        <SelectItem disabled value={void 0} text="Select a project..." />
        {#each projects as project}
        <SelectItem value={project} />
        {/each}
    </Select>
    <Button size="small" kind="ghost" on:click={() => newProject.openModal()}><Add/></Button>
    <Button size="small" kind="ghost" on:click={() => settingsOpen = true}><Settings/></Button>
</div>

<NewProjectModal
    bind:this={newProject}
    bind:spec={newProjectSpec}
    on:submit={createProject}/>
