<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script context="module" lang="ts">
    export type NoSpec = { type: 'none' };
    export type LocalSpec = { type: 'local', projectName: string };
    export type CloneSpec = { type: 'clone', url: string, token: string, projectName: string };
    export type Spec = NoSpec | LocalSpec | CloneSpec;
</script>

<script lang="ts">
    import { Modal, RadioTile, TextInput, TileGroup } from "carbon-components-svelte";
    import { pathDecompose, replaceExtension } from "./fsUtils.js";

    export let spec: Spec;

    let localSpec: LocalSpec;
    let cloneSpec: CloneSpec;
    let open = false;
    let selected: 'none' | 'local' | 'clone';

    function reset() {
        spec = { type: 'none' };
        selected = 'none';
        localSpec = { type: 'local', projectName: '' };
        cloneSpec = { type: 'clone', url: '', token: '', projectName: '' };
    }
    reset();

    export function openModal() {
        reset();
        open = true;
    }

    export function closeModal() {
        open = false;
    }

    $: spec =
        selected === 'local' ? localSpec :
        selected === 'clone' ? cloneSpec :
        { type: 'none' };
    $: acceptable = isAcceptable(spec);

    function isValidUrl(url: string): boolean {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    function isAcceptable(spec: Spec) {
        switch (spec.type) {
            case 'none': return false;
            case 'local': return spec.projectName !== '' && spec.projectName.indexOf('/') === -1;
            case 'clone': return isValidUrl(spec.url);
        }
    }

    export function defaultProjectName(spec: CloneSpec) {
        if (!isValidUrl(spec.url)) return '';
        const d = pathDecompose(spec.url);
        return replaceExtension(d.filename, '');
    }
</script>

<Modal
    bind:open
    modalHeading="New Project"
    primaryButtonText="Create"
    secondaryButtonText="Cancel"
    primaryButtonDisabled={!isAcceptable(spec)}
    on:click:button--secondary={() => open = false}
    on:submit
>
    <TileGroup bind:selected>
        <RadioTile value="local">
            <h4>Local project</h4>
            <p>Create a project in this browser instance only. You can configure a git remote later to push to and pull from.</p>
            <TextInput on:focus={() => selected = 'local'} labelText="Project name" helperText="A directory name, no slashes" bind:value={localSpec.projectName}/>
        </RadioTile>
        <RadioTile value="clone">
            <h4>Clone Git repository</h4>
            <p>Clone a remote git repository into this browser instance.</p>
            <TextInput on:focus={() => selected = 'clone'} labelText="Git repository https URL, e.g. https://gitlab.com/tonyg/smol-examples" bind:value={cloneSpec.url}/>
            <TextInput on:focus={() => selected = 'clone'} labelText="Access token" bind:value={cloneSpec.token}/>
            <TextInput on:focus={() => selected = 'clone'} labelText="Project name" placeholder={defaultProjectName(cloneSpec)} bind:value={cloneSpec.projectName}/>
        </RadioTile>
    </TileGroup>
</Modal>
