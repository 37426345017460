<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { exhaustive, Vm } from "@smolts/interpreter";
    import SmolLocation from "SmolLocation.svelte";
    import SmolVector from "SmolVector.svelte";
    import { smolSettings } from "./smolSettings.js";

    export let vm: Vm.VM;
    export let v: Vm.Value | null;
    $: c = v !== null && typeof v === 'object' && 'pointer' in v ? vm.readStore(v.pointer) : null;

    let className = ''; export { className as class };
    export let style = '';

    function description(s: symbol): string {
        return s.description!;
    }
</script>

<style lang="scss">
    .smol-value {
        background: var(--smol-value-background);
        color: var(--smol-value-color);
        padding: 0 var(--smol-value-padding-x);
        border-radius: var(--smol-value-border-radius);
    }

    .v-atom { background: #ddd; color: black; }
    .v-primitive { background: var(--v-primitive-background); color: var(--v-primitive-color); }
    .v-cell { background: #eee; color: black; }
    .v-closure { background: var(--v-closure-background); color: var(--v-closure-color); }
    .v-dynamic-closure { background: var(--v-dynamic-closure-background); color: var(--v-dynamic-closure-color); }
    .v-vector { background: #cff; color: black; }
    .v-env { background: #cfc; color: black; }
    .v-unknown { background: red; color: white; }
    .smol-value i { font-style: italic; }
</style>

{#if v === null}
<span style="{style}" class="{className} smol-value v-unknown">uninitialized</span>
{:else if typeof v === 'symbol'}
<span style="{style}" class="{className} smol-value v-atom"><code>{description(v)}</code></span>
{:else if typeof v === 'string' || typeof v === 'number'}
<span style="{style}" class="{className} smol-value v-atom"><code>{JSON.stringify(v)}</code></span>
{:else if typeof v === 'boolean'}
<span style="{style}" class="{className} smol-value v-atom"><code>{v ? '#t' : '#f'}</code></span>
{:else if typeof v === 'function'}
<span style="{style}" class="{className} smol-value v-primitive"><code>{v.name}</code></span>
{:else if typeof v === 'object'}
    {#if 'record' in v}
    <span style="{style}" class="{className} smol-value v-record">〈<svelte:self {vm} v={v.record.name}/>{#each v.fields as f
        }<svelte:self {vm} v={f}/>{/each}〉</span>
    {:else if !c}
    <span style="{style}" class="{className} smol-value v-unknown">??? absurd</span>
    {:else if c.type === 'cell'}
        {#if $smolSettings.hideCells}
        <svelte:self vm={vm} v={c.cell}/>
        {:else}
        <span style="{style}" class="{className} smol-value v-cell"><i>cell</i> <SmolLocation loc={v.pointer}/> <svelte:self {vm} v={c.cell}/></span>
        {/if}
    {:else if c.type === 'closure'}
    <span style="{style}" class="{className} smol-value v-closure"><i>closure</i> <SmolLocation loc={v.pointer}/></span>
    {:else if c.type === 'dynamic-closure'}
    <span style="{style}" class="{className} smol-value v-dynamic-closure"><i>dynamic-closure</i> <SmolLocation loc={v.pointer}/></span>
    {:else if c.type === 'vector'}
    <span style="{style}" class="{className} smol-value v-vector"><SmolLocation loc={v.pointer}/><SmolVector vm={vm} vs={c.vector}/></span>
    {:else if c.type === 'env'}
    <span style="{style}" class="{className} smol-value v-env"><i>env</i> <SmolLocation loc={v.pointer}/></span>
    {:else if exhaustive(c)}
    <span style="{style}" class="{className} smol-value v-unknown">??? <SmolLocation loc={v.pointer}/></span>
    {/if}
{:else if exhaustive(v)}
<span style="{style}" class="{className} smol-value v-unknown">??? {typeof v}</span>
{/if}