/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import type FS from "@isomorphic-git/lightning-fs";

export function pathJoin(a: string, b: string): string {
    if (b[0] === '/') return b;
    if (a[a.length - 1] === '/') return a + b;
    return a + '/' + b;
}

export function isFsError(e: any): e is { code: string } {
    return typeof e === 'object' && e && 'code' in e;
}

export async function rmtree(fs: FS, path: string) {
    let entries: string[];
    try {
        entries = await fs.promises.readdir(path);
    } catch (e) {
        if (isFsError(e)) {
            switch (e.code) {
                case 'ENOENT': return;
                case 'ENOTDIR': return await fs.promises.unlink(path);
                default: break;
            }
        }
        throw e;
    }
    await Promise.all(entries.map(leaf => rmtree(fs, pathJoin(path, leaf))));
    return fs.promises.rmdir(path);
}

export type DecomposedPath = {
    dir: string,
    filename: string,
    extension: string,
}

export function pathDecompose(path: string): DecomposedPath {
    const i = path.lastIndexOf('.');
    const j = path.lastIndexOf('/');
    return {
        dir: (j === -1) ? '/' : path.slice(0, j),
        filename: (j === -1) ? path : path.slice(j + 1),
        extension: (i === -1 || j > i) ? '' : path.slice(i + 1),
    };
}

export function replaceExtension(path: string, newExtension: string): string {
    const i = path.lastIndexOf('.');
    const j = path.lastIndexOf('/');
    if (i !== -1 && j < i) {
        return path.slice(0, i) + newExtension;
    } else {
        return path + newExtension;
    }
}

export async function maybeStat(fs: FS, path: string): Promise<FS.Stats | null> {
    try {
        return await fs.promises.stat(path);
    } catch (e) {
        if (isFsError(e) && e.code === 'ENOENT') {
            return null;
        }
        throw e;
    }
}