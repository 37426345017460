<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import SmolTab from './SmolTab.svelte';
    import type FS from '@isomorphic-git/lightning-fs';

    export let fs: FS;
    export let filePath: string;

    let source = ';; placeholder';

    async function loadFile(fs: FS, filePath: string) {
        source = '';
        try {
            source = await fs.promises.readFile(filePath, 'utf8') as string;
        } catch (e) {}
    }

    async function saveFile(contents: string) {
        await fs.promises.writeFile(filePath, contents, 'utf8');
    }

    $: loadFile(fs, filePath);
    $: saveFile(source);
</script>

<SmolTab bind:source/>
