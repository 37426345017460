/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { Span } from "span";
import * as Ast from "./ast";
import * as VariableAnalysis from "./variableanalysis";
import "./sets";

export type Value<Ctx, C extends object, A extends object> =
    symbol | string | number | boolean | Rec<Ctx, C, A> | C /* Closure */ | A /* Array */ | Primitive<Ctx, C, A>;

export type Rec<Ctx, C extends object, A extends object> = { record: RecType, fields: Value<Ctx, C, A>[] };
export type RecType = { name: symbol, fields: symbol[] };

export type Closure<Env> = { env: Env, formals: Ast.Var[], body: Ast.Body };
export type Primitive<Ctx, C extends object, A extends object> = PrimitiveFunction<Ctx, C, A> & { argv: number };
export type PrimitiveFunction<Ctx, C extends object, A extends object> =
    (ctx: Ctx, callSpan: Span, ... args: Value<Ctx, C, A>[]) => Value<Ctx, C, A>;

export type Scope<V> = { [key: string]: V };
export type Environment<V, L> = {scope: Scope<V>, next: L};
export type EnvChain<V, L> = (l: L) => Environment<V, L> | null;

export function isRecord<Ctx, C extends object, A extends object>(v: Value<Ctx, C, A>): v is Rec<Ctx, C, A> {
    return typeof v === 'object' && v !== null && 'record' in v;
}

export function definedNames(terms: Ast.Term[]): Ast.Var[] {
    return terms.flatMap(t => {
        switch (t.type) {
            case 'defvar': return [t.name];
            case 'deffun': return [t.name];
            case 'defrec': return [t.name];
            default: return [];
        }
    });
}

export function boundNames<V, L>(chain: EnvChain<V, L>, env: Environment<V, L> | null): Set<string> {
    const names = new Set<string>();
    while (env !== null) {
        for (const n of Object.keys(env.scope)) names.add(n);
        env = chain(env.next);
    }
    return names;
}

export function lookup<V, L>(chain: EnvChain<V, L>, env: Environment<V, L> | null, n: string): { value: V } | 'missing' {
    while (env !== null) {
        if (n in env.scope) {
            return { get value() { return env!.scope[n]; }, set value(v: V) { env!.scope[n] = v; } };
        }
        env = chain(env.next);
    }
    return 'missing';
}

export function makeDeepClosure<Env>(env: Env, formals: Ast.Var[], body: Ast.Body): Closure<Env> {
    return { env, formals, body };
}

export function makeFlatClosure<V, L>(
    chain: EnvChain<V, L>,
    env: Environment<V, L> | null,
    promote: (s: Scope<V>) => L,
    formals: Ast.Var[],
    body: Ast.Body,
): Closure<L> {
    const inScope = boundNames(chain, env);
    const r = VariableAnalysis.scope(inScope, formals.map(v => v.name), body);
    const captured = Set.intersection(r.freeNames, inScope);
    // const unbound = Set.difference(r.freeNames, captured);
    const scope: Scope<V> = {};
    captured.forEach(n => {
        const entry = lookup(chain, env, n);
        if (entry === 'missing') throw new Error("Internal error: unbound variable in flat closure");
        scope[n] = entry.value;
    });
    return { env: promote(scope), formals, body };
}
