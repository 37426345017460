<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts" context="module">
    export type FileTreeEventMap = {
        openFile: string,
        openDir: string,
        deleted: string,
        renamed: { old: string, new: string },
    };
</script>
<script lang="ts">
    import type FS from "@isomorphic-git/lightning-fs";
    import { isFsError, maybeStat, pathDecompose, pathJoin, rmtree } from "./fsUtils.js";
    import { createEventDispatcher } from "svelte";
    import { Button, OverflowMenu, OverflowMenuItem } from "carbon-components-svelte";
    import { CaretRight, CaretDown, DocumentAdd, FolderAdd, Folder, Document } from 'carbon-icons-svelte';
    import ClickableText from "./ClickableText.svelte";
    import { smolSettings } from "./smolSettings.js";

    const dispatch = createEventDispatcher<FileTreeEventMap>();

    export let fs: FS;
    export let path: string;
    export let label: string;
    let className = ''; export { className as class };
    export let style = '';

    export let selectedPath: string;
    $: isSelected = selectedPath === path;
    let isOpen: boolean = selectedPath.startsWith(path);

    let children: string[] = [];
    let isDir = false;
    $: isFile = !isDir;
    async function rescan(path: string, showHiddenFiles: boolean) {
        try {
            children = await fs.promises.readdir(path);
            if (!showHiddenFiles) {
                children = children.filter(n => !n.startsWith('.'));
            }
            children.sort();
            isDir = true;
        } catch (e) {
            if (isFsError(e) && e.code === 'ENOTDIR') {
                children = [];
                isDir = false;
                return;
            }
        }
    }
    $: rescan(path, $smolSettings.showHiddenFiles);

    const _rescan = () => rescan(path, $smolSettings.showHiddenFiles);
    export { _rescan as rescan };

    function click(e: Event) {
        e.preventDefault();
        if (!isOpen) {
            isOpen = true;
        } else if (isSelected) {
            isOpen = false;
        }
        selectedPath = path;
    }

    function dblclick(e: Event) {
        e.preventDefault();
        dispatch(isFile ? 'openFile' : 'openDir', path);
    }

    async function createFile(e: Event) {
        e.stopPropagation();
        const name = prompt('New file name?');
        if (name === null) return;
        const newPath = pathJoin(path, name);
        await fs.promises.writeFile(newPath, '');
        await _rescan();
        selectedPath = newPath;
        isOpen = true;
        dispatch('openFile', newPath);
    }

    async function createDir(e: Event) {
        e.stopPropagation();
        const name = prompt('New folder name?');
        if (name === null) return;
        const newPath = pathJoin(path, name);
        await fs.promises.mkdir(newPath);
        await _rescan();
        selectedPath = newPath;
        isOpen = true;
    }

    // Workaround bug(?) in OverflowMenu inside a custom element (?)
    // where the target gets set to the app element not the specific target involved
    let overflowMenu: OverflowMenu;
    function overflowMenuClicked(e: Event) {
        if (overflowMenu.buttonRef && overflowMenu.buttonRef.contains(e.target)) {
            // Hacky. queueMicrotask doesn't work here, it really has to be "later"
            setTimeout(() => overflowMenu.open = !overflowMenu.open, 0);
        }
    }

    async function deleteFile() {
        const message =
            isDir ? `Recursive delete ${JSON.stringify(path)}?` :
            `Delete file ${JSON.stringify(path)}?`;
        if (confirm(message)) {
            await rmtree(fs, path);
            dispatch('deleted', path);
        }
    }

    async function renameFile() {
        const old = pathDecompose(path);
        const newName = prompt(`New name for ${JSON.stringify(path)}?`, old.filename);
        if (newName === null) return;
        if (newName.indexOf('/') !== -1) {
            alert('Invalid file name');
            return;
        }
        const newPath = pathJoin(old.dir, newName);
        if (await maybeStat(fs, newPath) !== null) {
            alert('Target file already exists');
            return;
        }
        await fs.promises.rename(path, newPath);
        dispatch('renamed', { old: path, new: newPath });
    }

    async function childDeleted(e: CustomEvent) {
        if (selectedPath === e.detail) {
            selectedPath = path;
            await _rescan();
        }
        dispatch('deleted', e.detail);
    }

    async function childRenamed(e: CustomEvent) {
        if (selectedPath === e.detail.old) {
            selectedPath = e.detail.new;
            await _rescan();
        }
        dispatch('renamed', e.detail);
    }
</script>

<style lang="scss">
    @import "App.css";
    .selected { background: blue; color: white; }
    .container { display: flex; line-height: 2rem; }
    p { line-height: 2rem; }
    .toggle { min-width: 1rem; max-width: 1rem; }
    .body { flex: 1; }
    .label { padding-left: 0.5rem; }
</style>

<div class="container {className}" style="{style}">
    <div class="toggle">
        {#if isDir}
            <ClickableText on:click={click}>{#if isOpen}<CaretDown/>{:else}<CaretRight/>{/if}</ClickableText>
        {/if}
    </div>
    <div class="body">
        <div class="label" class:selected={isSelected}>
            <p style:text-wrap="nowrap">
                <ClickableText on:click={click} on:dblclick={dblclick}>
                    {#if isDir}<Folder/>{:else}<Document/>{/if} {label}
                </ClickableText>
            </p>
        </div>
        {#if isDir && isOpen}
            <div class="children">
                {#each children as child}
                <svelte:self
                    fs={fs}
                    path={pathJoin(path, child)}
                    label={child}
                    on:openFile
                    on:openDir
                    on:deleted={childDeleted}
                    on:renamed={childRenamed}
                    bind:selectedPath />
                {/each}
            </div>
        {/if}
    </div>
    <div class="buttons inline-panel-buttons">
        {#if isSelected}
            {#if isDir}
            <Button size="small" kind="ghost" on:click={createFile}><DocumentAdd/></Button>
            <Button size="small" kind="ghost" on:click={createDir}><FolderAdd/></Button>
            {/if}
            <OverflowMenu flipped size="sm" bind:this={overflowMenu} on:click={overflowMenuClicked}>
                <OverflowMenuItem text="Delete ..." on:click={deleteFile} />
                <OverflowMenuItem text="Rename ..." on:click={renameFile} />
                <slot name="overflow-menu"/>
            </OverflowMenu>
        {/if}
    </div>
</div>
