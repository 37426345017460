<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { Ast, exhaustive, Parse, ParseError, Read, Span, Values, VariableAnalysis, Vm } from "@smolts/interpreter";
  import { Button, Checkbox, FormItem } from "carbon-components-svelte";
  import { smolSettings } from "./smolSettings.js";

  export let source: string;
  export let skipApplyStates: boolean;
  export let skipBoringStates: boolean;
  export let cursorPos: number;

  export let vm: Vm.VM | null = null;
  export let parseErrors: ParseError.Suggestion[] = [];

  let root: HTMLElement;

  let history: Vm.State[] = [];
  let ast: Ast.Program | null = null;

  let notRunnable = true;
  let canStep = true;

  function onSourceChanged(source: string, unboundVariableCheck: boolean): void {
    handleStop();
    
    ast = null;
    notRunnable = true;
    parseErrors = [];
    try {
      const x = Read.read(source, true);
      ast = Parse.parseProgram(x);
      parseErrors = [];
      notRunnable = false;
    } catch (e) {
      if (e instanceof ParseError.ParseError) {
        parseErrors = e.suggestions;
        for (const s of e.suggestions) {
          console.error(Span.excerptSpan(source, s.span, s.suggestion));
        }
      } else {
        throw e;
      }
    }
    if (ast !== null && unboundVariableCheck) {
      const primitiveNames = Values.boundNames(_l => null, Vm.makeVMTop());
      const r = VariableAnalysis.program(new Set(), ast);
      const unbound = Set.differenceMap(v => v.name, r.freeReferences, primitiveNames);
      parseErrors.push(... Array.from(unbound).map(v =>
        ({ span: v.span, suggestion: `Unbound variable ${JSON.stringify(v.name)}` })));
    }
  }

  $: canStep = !!((!vm && ast) || (vm && !(vm.isStopped() || vm.isPenultimateStep())));

  // TODO: Ideally we'd avoid the `queueMicrotask` here.
  // Svelte seems to propagate changes out from onSourceChanged *unless*
  // it's the very first parse at page load time.
  // So the `queueMicrotask` is a grody hack to work around that problem.
  $: if (source !== void 0) queueMicrotask(() =>
    onSourceChanged(source, $smolSettings.lexicalClosures && $smolSettings.unboundVariableCheck));

  function updateVmState() {
    vm = vm; // reactive
    // (window as any).smol_vm = vm;
  }

  function handleStop() {
    vm = null;
    history = [];
    updateVmState();
  }

  function stepBackIfFinished() {
    if (vm && vm.isStopped() && vm.state.fault === null) {
      handleStepBackward();
    }
  }

  function handleRun() {
    if (vm === null) {
      startVM();
    }
    do {
      singleStep();
    } while (vm && !vm.isStopped());
    stepBackIfFinished();
    updateVmState();
  }

  function startVM() {
    if (ast !== null) {
      vm = new Vm.VM(Vm.makeVMTop(), ast);
      vm.useFlatClosures = $smolSettings.useFlatClosures;
      vm.lexicalClosures = $smolSettings.lexicalClosures;
      history = [];
      updateVmState();
    }
  }

  function singleStep() {
    if (vm !== null) {
      history.push(vm.state);
      history = history; // reactive
      vm.step();
    }
  }

  function handleStepForward() {
    if (vm === null) {
      startVM();
    } else {
      while (true) {
        singleStep();
        if (vm.isStopped()) break;
        if (skipApplyStates && vm.isApply()) continue;
        if (skipBoringStates
            && vm.state.instruction.type === 'eval'
            && (vm.state.instruction.code.type === 'const'
                || vm.state.instruction.code.type === 'var')) continue;
        break;
      }
      stepBackIfFinished();
      updateVmState();
    }
  }

  function handleStepBackward() {
    if (vm !== null) {
      const st = history.pop();
      history = history; // reactive
      if (st !== void 0) {
        vm.state = st;
        updateVmState();
      }
    }
  }

  function handleStepOver() {
    if (vm === null) {
      startVM();
    } else {
      const depth = vm.state.cont.length;
      const topFrame = vm.state.cont[depth - 1];
      while (!vm.isStopped()
             && ((vm.state.cont.length > depth)
                 || (vm.state.cont.length === depth && Object.is(vm.state.cont[depth - 1], topFrame))))
      {
        singleStep();
      }
    }
    stepBackIfFinished();
    updateVmState();
  }

  function handleRunToSpan(span: Span.Span) {
    let stepped = false;
    if (vm === null) {
      startVM();
      stepped = true;
    }
    do {
      if (vm && stepped) {
        const active = vm.state.instruction.type === 'eval'
          ? vm.state.instruction.code.span
          : vm.state.instruction.span;
        if (active.start >= span.start && active.end <= span.end) {
          break;
        }
      }
      singleStep();
      stepped = true;
    } while (vm && !vm.isStopped());
    stepBackIfFinished();
    updateVmState();
  }

  function handleRunToCursor(e: Event) {
    e.preventDefault();

    type N = Ast.Term | Ast.Body | Ast.Program;
    const spans: (Span.Span & { node: Ast.Node })[] = [];
    function searchAsts(startPos: number | null, n: N[]): number | null {
      n.forEach(m => startPos = searchAst(startPos, m));
      return startPos;
    }
    function searchAst(startPos: number | null, n: Ast.Term | Ast.Body | Ast.Program): number | null {
      let effectiveStart = startPos ?? n.span.start;
      if (cursorPos < effectiveStart || cursorPos >= n.span.end) return n.span.end;
      spans.push({start: effectiveStart, end: n.span.end, node: n });
      switch (n.type) {
        case "program": return searchAsts(effectiveStart, n.terms);
        case "body": return searchAst(searchAsts(effectiveStart, n.terms), n.expr);
        case "defvar": return searchAst(effectiveStart, n.expr);
        case "deffun": return searchAst(effectiveStart, n.body);
        case "defrec": return n.span.end;
        case "check": return searchAst(effectiveStart, n.body);
        case "const": return n.span.end;
        case "var": return n.span.end;
        case "set": return searchAst(effectiveStart, n.expr);
        case "cond": {
          let pos: number | null = effectiveStart;
          n.clauses.forEach(c => pos = searchAst(searchAst(pos, c.test), c.body));
          if (n.elseClause) pos = searchAst(pos, n.elseClause);
          return pos;
        }
        case "matchrec": {
          let pos: number | null = effectiveStart;
          pos = searchAst(pos, n.expr);
          n.clauses.forEach(c => pos = searchAst(pos, c.body));
          if (n.elseClause) pos = searchAst(pos, n.elseClause);
          return pos;
        }
        case "lambda": return searchAst(effectiveStart, n.body);
        case "let": {
          let pos: number | null = effectiveStart;
          n.bindings.forEach(b => pos = searchAst(pos, b.expr));
          return searchAst(pos, n.body);
        }
        case "begin": return searchAst(searchAsts(effectiveStart, n.prelude), n.expr);
        case "call": return searchAsts(searchAst(effectiveStart, n.operator), n.actuals);
        default:
          exhaustive(n);
      }
    }
    if (ast) searchAst(null, ast);

    spans.sort((a, b) => (a.end - a.start) - (b.end - b.start));
    if (spans.length > 0) handleRunToSpan(spans[0]);
  }
</script>

<style lang="scss">
  section {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  button {
    background: white;
    border: solid var(--cds-border-strong) 1px;
  }
  button.X {
    line-height: 0;
    padding: 0;
  }
  button.A { background: #eff; }
  button.B { background: pink; }
  button.C { background: #cfc; }
  button.D { background: #eff; }
  button.E { background: #ffc; }

  button > img {
    width: 32px;
    height: 32px;
    border: 0;
  }

  button:disabled { background: white; }
  button:disabled img {
    opacity: 0.2;
  }

  button.Y {
    line-height: 0;
    height: 32px;
    padding: 0 4px;
  }
  button:disabled.Y {
    opacity: 0.5;
  }
</style>

<section bind:this="{root}" class="fill-space">
  <div style:text-wrap="nowrap">
    <button class="X A" on:click={handleStepBackward} disabled={notRunnable} title="Step backward"><img alt="Step backward" src="img/icon-step-backward.svg"></button>
    <button class="X B" on:click={handleStop} disabled={notRunnable} title="Stop execution"><img alt="Reset VM" src="img/icon-stop.svg"></button>
    <button class="X C" on:click={handleRun} disabled={notRunnable || !canStep} title="Run program"><img alt="Run to completion" src="img/icon-play.svg"></button>
    <button class="X D" on:click={handleStepForward} disabled={notRunnable || !canStep} title="Step forward"><img alt="Step forward" src="img/icon-step-forward.svg"></button>
    <button class="X E" on:click={handleStepOver} disabled={notRunnable || !canStep} title="Step over"><img alt="Step over" src="img/icon-step-over.svg"></button>
  </div>
  <Checkbox style="flex: 0 0 auto;" labelText="Skip apply states" bind:checked={skipApplyStates}/>
  <Checkbox style="flex: 0 0 auto;" labelText="Skip boring actions" bind:checked={skipBoringStates}/>
  <button class="Y" on:click={handleRunToCursor} disabled={notRunnable}>Run to cursor</button>
  {#if vm}
  <FormItem style="flex: 0 0 auto;" class="bx--checkbox-wrapper">Step {history.length + 1}</FormItem>
  {/if}
</section>
