/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import FS from "@isomorphic-git/lightning-fs";
import { maybeStat, pathJoin } from "./fsUtils.js";

export async function createExampleProject(fs: FS) {
    const NAME = '00-example';
    const PATH = pathJoin('/projects', NAME);
    if (await maybeStat(fs, PATH) !== null) return;
    try {
        await fs.promises.mkdir('/projects');
    } catch (_e) {}
    await fs.promises.mkdir(PATH);
    for (const [name, source] of Object.entries(EXAMPLES)) {
        await fs.promises.writeFile(pathJoin(PATH, name + '.smol'), source.trim(), 'utf8');
    }
}

export const EXAMPLES: Record<string, string> = {
    evenodd: `
(deffun (even? n) (if (eq? n 0) #t (odd?  (- n 1))))
(deffun (odd? n)  (if (eq? n 0) #f (even? (- n 1))))
(even? 3)
`,
    aliasing: `
(defvar v1 (vec 1 7 3))
(defvar v2 v1)
(vec-set! v1 0 42)
(vec-ref v2 0)
`,
    'variable-not-found': `
(if #f
    Eh?
    123)
`,
    factorial: `
(deffun (fact n)
  (cond
    [(= n 0) 1]
    [else
     (* (fact (- n 1)) n)]))

(fact 5)
`,
    counter2: `
(defvar f
  (let ([n 0])
    (lambda ()
      (set! n (+ n 1))
      n)))
(f)
(f)
`,
    scope: `
(defvar x 1)
(deffun (addx y)
  (defvar x 2)
  (+ x y))
(+ (addx 0) x)
`,
    'let-over-lambda': `
(defvar f
  (let ([ctr 0])
    (lambda ()
      (set! ctr (+ ctr 1))
      ctr)))

(f)
(f)
`,
    object: `
(defvar mk-o-static
  (let ([counter 0])
    (lambda (amount)
      (set! counter (+ 1 counter))
      (lambda (m)
        (cond
         [(eq? m "inc")
          (lambda (n) (set! amount (+ amount n)))]
         [(eq? m "dec")
          (lambda (n) (set! amount (- amount n)))]
         [(eq? m "get")
          (lambda () amount)]
         [(eq? m "count")
          counter]
         [else
          (error "no such member")])))))

(defvar o1 (mk-o-static 1000))
(defvar o2 (mk-o-static 0))
(o1 "count")
(o2 "count")
`,
    counter1: `
(deffun (make-counter)
  (defvar n 0)
  (deffun (inc)
    (set! n (+ n 1))
    n)
  inc)
(defvar f (make-counter))
(f)
(f)
`,
    object1: `
(defvar mk-o-static
  (let ([counter 0])
    (lambda (amount)
      (set! counter (+ 1 counter))
      (lambda (m)
        (cond
         [(eq? m "inc")
          (lambda (n) (set! amount (+ amount n)))]
         [(eq? m "dec")
          (lambda (n) (set! amount (- amount n)))]
         [(eq? m "get")
          (lambda () amount)]
         [(eq? m "count")
          counter]
         [else
          (error "no such member")])))))

(defvar o1 (mk-o-static 1000))
((o1 "inc") 50)
((o1 "dec") 16)
;; (o1 "huh")
((o1 "get"))
`,
    fibonacci: `
(deffun (fib n)
  (cond
   [(<= n 1) 1]
   [else
    (+ (fib (- n 1))
       (fib (- n 2)))]))

(fib 0)
(fib 1)
(fib 2)
(fib 3)

(fib 5)
`,
    checks: `
(deffun (x v) (/ 10 v))

(check (= (x 5) 2))
(check (= (x 2) 2))
(check (= (x 0) 2))
`,
    map: `
(defrec cons head tail)
(defrec nil)

(deffun (map f xs)
  (matchrec xs
    [(nil) (nil)]
    [(cons head tail) (cons (f head) (map f tail))]))

(defvar xs (cons 1 (cons 2 (cons 3 (nil)))))
(map (lambda (x) (+ x 1)) xs)
`,
};
