<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Span, Vm } from "@smolts/interpreter";
    import SmolLocation from "./SmolLocation.svelte";
    import SmolValue from "./SmolValue.svelte";
    import SmolVector from "./SmolVector.svelte";
    import { smolSettings } from "./smolSettings.js";

    export let vm: Vm.VM;
    export let source: string;
    export let loc: Vm.Location | null;
    export let c: Vm.Storable;
    export let envOpen = false;

    let className = ''; export { className as class };
    export let style = '';

    $: scopeEntries = c.type === 'env' ? Object.entries(c.env.scope) : [];
    $: nextEnv = c.type === 'env' && c.env.next !== null ? {pointer: c.env.next} : null;

    function pos(offset: number): string {
        const p = Span.offsetToPosition(source, offset);
        return `${p.line}:${p.column}`;
    }
</script>

<style lang="scss">
    .v-cell { background: #eee; color: black; }
    .v-primitive { background: var(--v-primitive-background); color: var(--v-primitive-color); }
    .v-closure { background: var(--v-closure-background); color: var(--v-closure-color); }
    .v-dynamic-closure { background: var(--v-dynamic-closure-background); color: var(--v-dynamic-closure-color); }
    .v-vector { background: #cff; color: black; }
    .v-env { background: #cfc; color: black; }
    .v-unknown { background: red; color: white; }
    .smol-storable i { font-style: italic; }

    .v-env {
        overflow-x: auto;
    }
    .v-env > details > ul { margin-left: 1.333rem; }
    .v-env > details > ul > li {
        text-wrap: nowrap;
        line-height: 1.4;
    }
</style>

{#if c.type === 'vector'}
<div style="{style}" class="{className} smol-storable v-vector"><SmolLocation {loc}/><SmolVector vm={vm} vs={c.vector}/></div>
{:else if c.type === 'cell'}
    {#if $smolSettings.hideCells}
    <SmolValue vm={vm} v={c.cell}/>
    {:else}
    <div style="{style}" class="{className} smol-storable v-cell"><i>cell</i> <SmolLocation {loc}/> <SmolValue vm={vm} v={c.cell}/></div>
    {/if}
{:else if c.type === 'closure'}
<div style="{style}" class="{className} smol-storable v-closure"><i>closure</i> <SmolLocation {loc}/> env=<SmolLocation loc={c.closure.env}/> at {pos(c.closure.body.span.start)}</div>
{:else if c.type === 'dynamic-closure'}
<div style="{style}" class="{className} smol-storable v-dynamic-closure"><i>dynamic-closure</i> <SmolLocation {loc}/> at {pos(c.closure.body.span.start)}</div>
{:else if c.type === 'env'}
<div style="{style}" class="{className} smol-storable v-env">
    <details bind:open={envOpen}>
        <summary><i>env</i> <SmolLocation {loc}/></summary>
        <ul>
            {#each scopeEntries as [name, [span, loc]]}
            <li>
                <code>{name}</code>: {pos(span.start)}:
                <SmolValue vm={vm} v={({pointer: loc})}/>
            </li>
            {/each}
            {#if nextEnv}<li><i>next scope</i>: <SmolValue vm={vm} v={nextEnv}/></li>{/if}
        </ul>
    </details>
</div>
{:else}
<div style="{style}" class="{className} smol-storable v-unknown">???</div>
{/if}
