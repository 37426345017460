/// SPDX-License-Identifier: GPL-3.0-or-later
/// SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com>

import { writable } from "svelte/store";

export type SmolSettings = {
    skipApplyStates: boolean,
    skipBoringStates: boolean,
    hideCells: boolean,
    hideGarbage: boolean,
    useFlatClosures: boolean,
    hideFunctions: boolean,
    lexicalClosures: boolean,
    unboundVariableCheck: boolean,
    showHiddenFiles: boolean,
};

export const DEFAULT_SMOL_SETTINGS: SmolSettings = {
    skipApplyStates: false,
    skipBoringStates: false,
    hideCells: true,
    hideGarbage: true,
    hideFunctions: true,
    useFlatClosures: false,
    lexicalClosures: true,
    unboundVariableCheck: true,
    showHiddenFiles: false,
};

export const smolSettings = writable<SmolSettings>(DEFAULT_SMOL_SETTINGS);
