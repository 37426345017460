<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import type { Vm } from "@smolts/interpreter";

    export let loc: Vm.Location | null;
</script>
<style lang="scss">
    span {
        font-size: 80%;
    }
</style>
{#if loc !== null}
<span>#{loc}</span>
{/if}
