<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Button, Checkbox, Modal } from "carbon-components-svelte";
    import { smolSettings } from "./smolSettings.js";

    export let settingsOpen: boolean;
</script>

<style>
    section {
        margin: 1rem 0;
    }
</style>

<Modal
    bind:open={settingsOpen}
    modalHeading="Settings"
    passiveModal
>
    <section>
        <h4>SMoL runtime</h4>
        <Checkbox labelText="Hide cells (locations)" bind:checked={$smolSettings.hideCells}/>
        <Checkbox labelText="Hide garbage" bind:checked={$smolSettings.hideGarbage}/>
        <Checkbox labelText="Use flat closures" bind:checked={$smolSettings.useFlatClosures}/>
        <Checkbox labelText="Abbreviate closure and primitive-function values" bind:checked={$smolSettings.hideFunctions}/>
    </section>
    <section>
        <h4>SMoL language variations</h4>
        <Checkbox labelText="Lexical closures" bind:checked={$smolSettings.lexicalClosures}/>
    </section>
    <section>
        <h4>Static checks</h4>
        <Checkbox labelText="Check for unbound variables (requires lexical closures)"
            bind:checked={$smolSettings.unboundVariableCheck}
            disabled={!$smolSettings.lexicalClosures}/>
    </section>
    <section>
        <h4>Miscellaneous</h4>
        <Checkbox labelText="Show hidden files" bind:checked={$smolSettings.showHiddenFiles}/>
    </section>
    <section>
        <Button size="small" kind="secondary" on:click={() => document.documentElement.requestFullscreen()}>Enter full screen</Button>
    </section>
</Modal>
