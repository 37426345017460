<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
    import { Button, Tab } from "carbon-components-svelte";
    import { Close } from "carbon-icons-svelte";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher<{ 'closeTabId': string }>();

    export let id: string;
    export let label: string;
    export let pathPrefix: string | null;
    $: labelSuffix = label.slice(pathPrefix === null ? 0 : pathPrefix.length + (pathPrefix.endsWith('/') ? 0 : 1));

    function closeTab(e: Event) {
        e.stopPropagation();
        dispatch('closeTabId', id);
    }
</script>

<style label="scss">
    .tab-label {
        display: flex;
        align-items: center;
    }
    .tab-label > .text {
        flex: 1;
    }
</style>

<Tab label={label}>
    <div class="tab-label">
        <div class="text">{labelSuffix}</div>
        <Button
            on:click={closeTab}
            style="padding: 0 8px; margin-right: -8px;"
            size="small"
            kind="ghost"><Close/></Button>
    </div>
</Tab>
