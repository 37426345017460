<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { exhaustive, Span, Vm } from '@smolts/interpreter';
  import { plural } from './english.js';
  import SmolValue from './SmolValue.svelte';
  import VmStateControlFrame from './VmStateControlFrame.svelte';
  
  export let vm: null | Vm.VM;
  export let contextFrameSpan: null | Span.Span;

  let selectedFrame: null | Vm.Frame = null;
  $: contextFrameSpan = selectedFrame?.span ?? null;

  $: task = vm && vm.state.instruction.type === 'eval' ? vm.state.instruction.code : null;
  $: value = vm && vm.state.instruction.type === 'apply' ? vm.state.instruction.value : null;
  $: frames = vm ? vm.state.cont.slice().reverse() : [];
  $: if (vm === null) contextFrameSpan = null;
</script>

<h4>Control</h4>
{#if vm}
    <h5>{#if vm.state.instruction.type === 'apply'}Value{:else}Next Task{/if}</h5>
    <p>
        {#if task}
            {#if task.type === 'defvar'}
            Define the variable <code>{task.name.name}</code>
            {:else if task.type === 'deffun'}
            Define the function <code>{task.name.name}</code>
            {:else if task.type === 'defrec'}
            Define the record type <code>{task.name.name}</code>
            {:else if task.type === 'check'}
            Execute a test case
            {:else if task.type === 'body'}
            Execute a block
            {:else if task.type === 'begin'}
            Execute a sequence of instructions
            {:else if task.type === 'call'}
            Perform a function call
            {:else if task.type === 'cond'}
            Determine which branch to follow
            {:else if task.type === 'matchrec'}
            Determine which record type matches
            {:else if task.type === 'const'}
            Produce the literal constant <code>{'' + task.literal}</code>
            {:else if task.type === 'lambda'}
            Create a closure
            {:else if task.type === 'let'}
            Initialize and introduce {plural('variable', task.bindings.length)}
            <span class="comma-separated no-period">
                {#each task.bindings as b}
                <span><code>{b.varName.name}</code></span>
                {/each}
            </span>
            {:else if task.type === 'set'}
            Update variable <code>{task.varName.name}</code>
            {:else if task.type === 'var'}
            Look up the value of variable <code>{task.name}</code>
            {:else if exhaustive(task)}
            ???
            {/if}
        {/if}
        {#if value !== null}<SmolValue vm={vm} v={value} />{/if}
    </p>
    <ul>
      {#each frames as frame}
      <VmStateControlFrame {vm} {frame} bind:selectedFrame />
      {/each}
    </ul>
{/if}
