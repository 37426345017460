<!-- SPDX-License-Identifier: GPL-3.0-or-later -->
<!-- SPDX-FileCopyrightText: Copyright © 2024 Tony Garnock-Jones <tonyg@leastfixedpoint.com> -->

<script lang="ts">
  import { ParseError, Span, Vm } from '@smolts/interpreter';
  import { Column, Grid, Row } from 'carbon-components-svelte';
  import VmStateControl from './VmStateControl.svelte';
  import VmStateEnvironment from './VmStateEnvironment.svelte';
  import VmStateStore from './VmStateStore.svelte';
  import VmStateFault from './VmStateFault.svelte';

  export let source: string;
  export let vm: null | Vm.VM;
  export let parseErrors: ParseError.Suggestion[];
  export let contextFrameSpan: null | Span.Span;
</script>

<style lang="scss">
    @import "App.css";
</style>

<div>
  <VmStateFault {... { vm, source, parseErrors }} />
  <Grid>
    <Row>
      <Column>
        <VmStateControl vm={vm} bind:contextFrameSpan />
      </Column>
      <Column>
        <VmStateEnvironment vm={vm} source={source} />
      </Column>
      <Column>
        <VmStateStore vm={vm} source={source} />
      </Column>
    </Row>
  </Grid>
</div>
